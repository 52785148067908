import React, { useEffect, useState } from "react";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { profile, user2, user5 } from "../../../containers/assets";
import { updateProfile, updateProfileImage } from "../../../slice/userSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
const EditPopup = (props) => {
  const { show, setShow, profileDetails } = props;
  const dispatch = useDispatch();

  // const [show, setShow] = useState(false);
  const [file, setFile] = useState("");
  const imgUpload = () => {
    document.querySelector("input[type='file']").click();
  };

  useEffect(() => {
    formik.setValues({
      image: profileDetails?.profile_image
        ? profileDetails?.profile_image
        : user5,
      name: profileDetails?.name,
      email: profileDetails?.email,
      description: profileDetails?.description,
      facebook: profileDetails?.social_media.facebook,
      instagram: profileDetails?.social_media.instagram,
      twitter: profileDetails?.social_media.twitter,
      linkedin: profileDetails?.social_media.linkedin,
      youtube: profileDetails?.social_media.youtube,
      discord: profileDetails?.social_media.discord,
    });
  }, [profileDetails]);

  const onFileChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
    };

    reader.readAsDataURL(file);
    reader.onload = function (e) {
      console.log("DataURL:", e.target.result);
    };
    if (file) {
      let postData = {};
      postData.profile_image = file;
      dispatch(updateProfileImage(postData));
      // dispatch image uploader
    }
  };

  const formik = useFormik({
    initialValues: {
      image: profileDetails?.profile_image,
      name: "",
      email: "",
      description: "",
      facebook: "",
      instagram: "",
      twitter: "",
      linkedin: "",
      youtube: "",
      discord: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please Enter name"),
      description: Yup.string().required("Please Enter description"),
      email: Yup.string()
        .email("Enter valid email")
        .required("Please Enter email"),
    }),

    onSubmit: (values) => {
      let obj = {
        name: values.name,
        email: values.email,
        description: values.description,
        facebook: values.facebook,
        instagram: values.instagram,
        twitter: values.twitter,
        linkedin: values.linkedin,
        youtube: values.youtube,
        discord: values.discord,
      };
      dispatch(updateProfile(obj));
    },
  });
  console.log(formik.values);

  return (
    <>
      <Modal
        show={show}
        size={props && props.size ? props.size : "sm"}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <input
          type="file"
          id="file-upload"
          style={{ display: "none" }}
          onChange={(e) => onFileChange(e)}
        />
        <Modal.Body>
          <div style={{ width: "100%" }}>
            <div className="edit-img">
              <img
                className="rounded-circle"
                src={formik.values.image}
                alt=""
              />
              <i className="fa fa-edit overlay" onClick={() => imgUpload()}></i>
            </div>
            <p className="text-secondary">* Reqiured Fileds</p>

            <div className="login-form-feild">
              <label>Name *</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label className="text-danger">
                {formik.errors.name && formik.touched.name
                  ? formik.errors.name
                  : ""}
              </label>
            </div>
            <div className="login-form-feild">
              <label>email *</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label className="text-danger">
                {formik.errors.name && formik.touched.email
                  ? formik.errors.email
                  : ""}
              </label>
            </div>
            <div className="login-form-feild">
              <label>Description *</label>
              <input
                type="text"
                className="form-control"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label className="text-danger">
                {formik.errors.name && formik.touched.description
                  ? formik.errors.description
                  : ""}
              </label>
            </div>
            <div className="login-form-feild">
              <label>Facebook</label>
              <input
                type="text"
                className="form-control"
                name="facebook"
                value={formik.values.facebook}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
              />
            </div>
            <div className="login-form-feild">
              <label>Instagram</label>
              <input
                type="text"
                className="form-control"
                name="instagram"
                value={formik.values.instagram}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
              />
            </div>
            <div className="login-form-feild">
              <label>Twitter</label>
              <input
                type="text"
                className="form-control"
                name="twitter"
                value={formik.values.twitter}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
              />
            </div>
            <div className="login-form-feild">
              <label>YouTube</label>
              <input
                type="text"
                className="form-control"
                name="youtube"
                value={formik.values.youtube}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
              />
            </div>
            <div className="login-form-feild">
              <label>Linkedin</label>
              <input
                type="text"
                className="form-control"
                name="linkedin"
                value={formik.values.linkedin}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
              />
            </div>
            <button
              type="submit"
              className="btn custom-btn wallet-btn"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditPopup;

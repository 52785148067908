import React, { useState } from "react";
import { Modal, Button, InputGroup, Input, FormControl } from "react-bootstrap";
import { createSale } from "../../blockchain/marketplaceContract";

const CreateSaleModal = (props) => {
  const { showSale, setShowSale, tokenId, nftContractAddress } = props;
  let [buyNowPrice, setBuyNowPrice] = useState("");
  let [whiteListedAddress, setWhiteListedAddress] = useState("");
  const [error, setError] = useState(false);

  const AddressZero = "0x0000000000000000000000000000000000000000";

  const submitHandler = async () => {
    setShowSale(false);
    let feeRecipients = [];
    let feePercentages = [];

    if (whiteListedAddress === "") {
      setWhiteListedAddress(AddressZero);
    }

    const saleData = await createSale(
      nftContractAddress,
      tokenId,
      buyNowPrice.toString(),
      whiteListedAddress,
      feeRecipients,
      feePercentages
    );
  };

  const handleSetBuyNowPrice = (e) => {};

  const handleClose = () => {
    setShowSale(false);
  };
  return (
    <>
      <Modal show={showSale}>
        <Modal.Header>
          <Modal.Title>Create Sale</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Enter Buy Now Price"
                aria-describedby="basic-addon2"
                onChange={(e) => setBuyNowPrice(e.target.value)}
                value={buyNowPrice}
              />
              <InputGroup.Text id="basic-addon2">ETH</InputGroup.Text>
            </InputGroup>

            {error ? (
              <label className="text-danger">
                Bid amount must be grater than current bid amount
              </label>
            ) : (
              ""
            )}
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Enter Whitelisted Address"
                aria-describedby="basic-addon2"
                onChange={(e) => setWhiteListedAddress(e.target.value)}
                value={whiteListedAddress}
              />
            </InputGroup>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => submitHandler()}>
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default CreateSaleModal;

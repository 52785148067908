import React, { useEffect, useState } from "react";
import { logo, user1, user5 } from "../../../containers/assets";
import { Nav, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, logout } from "../../../slice/userSlice";
import { useNavigate, Link, NavLink } from "react-router-dom";
import ConnectWallet from "../../../components/ConnectWallet/ConnectWallet";
import { ethers } from "ethers";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileDetails, setProfileDetails] = useState();

  const [isLoggedInRes, setIsLoggedInRes] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [userName, setUsername] = useState({
    firstname: "",
    lastname: "",
    profile: "",
  });
  const { profileData } = useSelector((state) => {
    return { profileData: state.user };
  });

  const [token, setToken] = useState(JSON.parse(localStorage.getItem("user")));

  const [status, setStatus] = useState(false);
  const [isInstalledMetamask, setInstalledMetamask] = useState(false);
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (
      profileData &&
      profileData.value != undefined &&
      profileData.value != ""
    ) {
      setProfileDetails({
        userProfile: {
          type: "img",
          value: profileData.value.user.profile_image,
        },
        name: { type: "text", value: profileData.value.user.name },
      });
    }
  }, [profileData]);

  const { auth, logoutRes } = useSelector((state) => {
    return { auth: state.auth, logout: state.logout };
  });
  const [flag, setFlag] = useState(false);
  const [pathName, setPathName] = useState("Home");
  const [path, setPath] = useState([
    // { url: "/", name: "Home", status: true },
    // { url: "/explore-list", name: "Explore", status: false },
    { url: "/", name: "Explore", status: false },

    // { url: "/", name: "Library", status: false },
    // { url: "/", name: "Activity", status: false },
    // { url: "/", name: "Faq", status: false },
    // { url: "/items", name: "Create", status: false },
    // { url: "/explore-minting", name: "Explore Minting", status: false },
  ]);

  useEffect(async () => {
    dispatch(getProfile());

    if (typeof window.ethereum !== "undefined") {
      if (token) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();
        setAddress(await signer.getAddress());

        window.ethereum._metamask.isUnlocked().then((status) => {
          if (status) {
            setStatus(true);
          } else {
            window.ethereum.request({ method: "eth_requestAccounts" });
            setStatus(false);
          }
        });
      } else {
        setStatus(false);
      }

      setInstalledMetamask(true);
      window.ethereum.on("accountsChanged", function (data) {
        if (data.length != 0 && token.accessToken) {
          setStatus(true);
        } else {
          setStatus(false);
        }

        window.location.reload();
      });
    }
  }, []);

  useEffect(() => {
    if (logoutRes && Object.keys(logoutRes) > 0) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (token) {
      setIsLoggedInRes(true);
    }
  }, [token]);

  const logoutHandler = () => {
    if (token) {
      dispatch(logout(token));
    }
  };

  const activeHandler = (index, name) => {
    let obj = path;
    setPathName(name);
    obj.filter((x) => {
      return (x.status = false);
    });
    obj[index].status = !obj[index].status;
    setPath(obj);
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleOnChangePage = (link) => {
    navigate("/" + link);
  };
  const handleMyCollectionPage = () => {
    let wallet_address = JSON.parse(localStorage.getItem("user")).address;
    navigate(`/profile`);
  }

  const addDefaultSrc = (ev) => {
    console.log(ev);
    ev.target.src = user5;
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="top-header">
            <div className="logo">
              <a href="https://www.newwealthcolab.com/" target="_blank">
                <img src={logo} alt="" />
              </a>
            </div>
            {/* <div className="top-search">
              <input type="text" placeholder="Search" />
              <button type="button" className="top-search-btn"><i className="fa fa-search"></i></button>
            </div> */}
            <div className="nav-right">
              <nav className="main-nav" id="cssmenu">
                <div
                  className={flag ? "button menu-opened" : "button"}
                  onClick={() => setFlag(!flag)}
                ></div>
                <ul
                  className={flag ? "open" : ""}
                  style={{ display: flag ? "block" : "" }}
                >
                  {path && path.length > 0
                    ? path.map((x, index) => {
                        return (
                          <li key={index}>
                            <NavLink
                              onClick={() => activeHandler(index, x.name)}
                              to={x && x.url ? x.url : ""}
                              activeClassName={
                                pathName === x.name ? "active" : "inactive"
                              }
                            >
                              {x && x.name ? x.name : ""}
                            </NavLink>
                          </li>
                        );
                      })
                    : ""}

                  {isLoggedInRes || token ? (
                    <>
                      <li>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="transparent"
                            id="dropdown-basic"
                          >
                            <span className="user_icon">
                              <img
                                onError={addDefaultSrc}
                                className="header-profile-icon rounded-circle"
                                src={
                                  profileDetails &&
                                  profileDetails.userProfile &&
                                  profileDetails.userProfile != ""
                                    ? profileDetails.userProfile.value
                                    : user5
                                }
                                alt="profile"
                                width="50"
                                height="50"
                              />
                            </span>
                            <span className="name-dropdown">
                              {userName.firstname + " " + userName.lastname}
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleOnChangePage("profile")}
                              className="p-2"
                            >
                              <i
                                className="fa fa-user mr-2"
                                aria-hidden="true"
                              ></i>{" "}
                              My Profile
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="p-2"
                              onClick={handleMyCollectionPage}
                            >
                              <i
                                className="fa fa-grid mr-2"
                                aria-hidden="true"
                              ></i>{" "}
                              My Collection
                            </Dropdown.Item>

                            <Dropdown.Divider />
                            <Dropdown.Item
                              className="p-2"
                              onClick={() => logoutHandler()}
                            >
                              <i
                                className="fa fa-sign-out mr-2"
                                aria-hidden="true"
                              ></i>
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                </ul>
              </nav>
            </div>

            <div className="btn-right">
              {/* {auth && auth.isLoggedin ? '' : <a href="/login" className="btn custom-btn">Log In</a>} */}
              {status ? (
                <button className="btn custom-btn wallet-btn address-btn">
                  {address}
                </button>
              ) : (
                <button
                  onClick={handleOpenPopup}
                  className="btn custom-btn wallet-btn"
                >
                  Connect Wallet
                </button>
              )}

              {/* <button onClick={handleOpenPopup} className="btn custom-btn wallet-btn">Connect Wallet</button> */}
            </div>
          </div>
        </div>
      </header>
      <ConnectWallet show={showPopup} setShowPopup={setShowPopup} />
    </>
  );
};

export default Header;

import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiConstants } from "../constants/api.constants";
import { commonService } from "../service/common.service";
import { setAlert } from "../slice/alertSlice";

export const createKyc = createAsyncThunk(
  apiConstants.CREATE_KYC,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withTokenAndFormData(apiConstants.CREATE_KYC, data)
      .then(async (response) => {
        if (response.data.success) {
          dispatch(
            setAlert({
              msg: "Kyc created ...",
              alertType: "success",
              timeout: 6000,
            })
          );
          return response.data.success;
        } else {
          let result = await errorHandler(response);
          dispatch(
            setAlert({
              msg: JSON.stringify(result),
              alertType: "error",
              timeout: 6000,
            })
          );
          return response.data.success;
        }
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

const errorHandler = async (response) => {
  try {
    if (response) {
      if (Array.isArray(response.data.errors)) {
        let arr = response.data.errors.map((x, index) => {
          if (index !== response.data.errors.length) {
            return x.msg + ",";
          } else {
            return x.msg;
          }
        });
        return arr;
      } else {
        if (response.data && response.data.msg) {
          return response.data.msg;
        }
      }
    }
  } catch (err) {
    console.log("======= Error in errorHandler() kycSlice ========", err);
  }
};

const initialState = {
  value: [],
  status: "",
};

export const kycSlice = createSlice({
  name: "kyc",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [createKyc.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "created";
    },
  },
});

export default kycSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConstants } from "../constants/api.constants";
import { setAlert } from "./alertSlice";
import { authentication } from "./authSlice";
import { commonService } from "../service/common.service";
import { Navigate } from "react-router-dom";

const url = process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BASE_API;

export const logout = createAsyncThunk(
  apiConstants.LOGOUT,
  async (data, { dispatch, rejectWithValue }) => {
    commonService
      .getDataWithToken(apiConstants.LOGOUT, data)
      .then((response) => {
        localStorage.removeItem("user");
        dispatch(authentication({ value: "", isLoggein: false }));
        if (response.data.success) {
          console.log(response);
          dispatch(
            setAlert({
              msg: "Logged out successfully",
              alertType: "success",
              timeout: 6000,
            })
          );
          window.location.reload();
        }
        return response.data.success;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.repsonse);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const updateProfile = createAsyncThunk(
  apiConstants.UPDATE_PROFILE,
  async (data, { dispatch, rejectWithValue }) => {
    commonService
      .withToken(apiConstants.UPDATE_PROFILE, data)
      .then((response) => {
        dispatch(
          setAlert({
            msg: "Profile updated successfully",
            alertType: "success",
            timeout: 6000,
          })
        );
        window.location.reload();
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const updateProfileImage = createAsyncThunk(
  apiConstants.UPDATE_PROFILE_IMAGE,
  async (data, { dispatch, rejectWithValue }) => {
    commonService
      .withTokenAndFormData(apiConstants.UPDATE_PROFILE_IMAGE, data)
      .then((response) => {
        dispatch(
          setAlert({
            msg: "Profile Image updated successfully",
            alertType: "success",
            timeout: 6000,
          })
        );
        // window.location.reload()
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const subscribeUser = createAsyncThunk(
  apiConstants.SUBSCRIBE,
  async (data, { dispatch, rejectWithValue }) => {
    commonService
      .withToken(apiConstants.SUBSCRIBE, data)
      .then((response) => {
        dispatch(
          setAlert({
            msg: "User Subscribed successfully",
            alertType: "success",
            timeout: 6000,
          })
        );
        // window.location.reload()
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const getProfile1 = createAsyncThunk(
  apiConstants.GET_PROFILE,
  async (data, { dispatch, rejectWithValue }) => {
    commonService
      .getDataWithToken(apiConstants.GET_PROFILE, data)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        }
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const getProfile = createAsyncThunk(
  apiConstants.GET_PROFILE,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .getDataWithToken(apiConstants.GET_PROFILE, data)
      .then((response) => {
        return response.data;
      })
      .catch(async (err) => {

        let result = await errorHandler(err.response);
        localStorage.clear()
        Navigate('/')
        dispatch(
          setAlert({
            msg: "Please Login again",
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const getProfileWalletAddress = createAsyncThunk(
  apiConstants.GET_PROFILE_WALLET_ADDRESS,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withOutToken(apiConstants.GET_PROFILE_WALLET_ADDRESS, data)
      .then((response) => {
        return response.data;
      })
      .catch(async (err) => {

        let result = await errorHandler(err.response);
        // localStorage.clear()
        // Navigate('/')
        
        rejectWithValue(err);
      });
  }
);

export const login = createAsyncThunk(
  apiConstants.LOGIN,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withOutToken(apiConstants.LOGIN, data)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem(
            "user",
            JSON.stringify({
              accessToken: response.data.accessToken,
              role: response.data.role,
              address: data.wallet_address,
              profile_image: response.data.profile_image,
            })
          );
        }
        dispatch(
          authentication({
            value: response.data,
            isLoggedin: true,
            address: data.wallet_address,
          })
        );
        // dispatch(getProfile())
        if (response.data.success) {
          dispatch(
            setAlert({
              msg: "Logged in successfully",
              alertType: "success",
              timeout: 6000,
            })
          );
          window.location.reload();
        }
        return response.data.success;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

const errorHandler = async (response) => {
  try {
    if (response) {
      let arr = response.data.errors.map((x, index) => {
        if (index !== response.data.errors.length) {
          return x.msg + ",";
        } else {
          return x.msg;
        }
      });
      return arr;
    }
  } catch (err) {
    console.log("======== Error in ErrorHandler user slice ========", err);
  }
};

const initialState = {
  value: "",
  status: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.value = action.payload;
      state.status = "fetched";
    },
    [getProfileWalletAddress.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.value = action.payload;
      state.status = "fetched";
    },
    [login.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "created";
    },
    [logout.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "logged out";
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "Profile Updated";
    },
    [subscribeUser.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "Profile Updated";
    },

    [updateProfileImage.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "Profile Updated";
    },
  },
});

export default userSlice.reducer;

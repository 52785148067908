import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConstants } from "../constants/api.constants";
import { setAlert } from "./alertSlice";
import { authentication } from "./authSlice";
import { commonService } from "../service/common.service";

const url = process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BASE_API;

export const getAllUnmintedNft = createAsyncThunk(
  apiConstants.GET_ALL_UNMINTED_NFT,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withOutToken(`${apiConstants.GET_ALL_UNMINTED_NFT}?search=${data.search}`, data)
      .then((response) => {
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);


const errorHandler = async (response) => {
  try {
    if (response) {
      let arr = response.data.errors.map((x, index) => {
        if (index !== response.data.errors.length) {
          return x.msg + ",";
        } else {
          return x.msg;
        }
      });
      return arr;
    }
  } catch (err) {
    console.log("======== Error in ErrorHandler user slice ========", err);
  }
};

const initialState = {
  value: "",
  status: "",
};

export const itemSlice = createSlice({
  name: "membership",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getAllUnmintedNft.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
  },
});

export default itemSlice.reducer;

import { createSlice, current } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  value: "",
  isLoggedin: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    updateAuth: (state, action) => {
      if (action && action.payload) {
        let newState = action.payload;
        state = newState;
        return state;
      }
    },
  },
});

const { updateAuth } = authSlice.actions;

export const authentication = createAsyncThunk(
  "/auth",
  async (x, { dispatch }) => {
    let token = localStorage.getItem("user");
    dispatch(updateAuth(x));
    return token;
  }
);

export default authSlice.reducer;

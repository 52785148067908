import React, { useEffect, useState } from "react";
import Faq from "../../Faq/Faq";
import { ethers } from "ethers";

import { useLocation, useParams } from "react-router-dom";

// import ActivityList from "../RecentActivity/ActivityList";
import Header from "../../Common/Header/Header";
import Footer from "../../Common/Footer/Footer";
import BidHistory from "../../BidHistory/BidHistory";
import Accordion from "react-bootstrap/Accordion";
import { category1, recent2, recent3, user2 } from "../../../containers/assets";
import {
  getAuctionData,
  makeBid,
  ownerOfNFT,
} from "../../../blockchain/marketplaceContract";
import MakeOfferModal from "./MakeOfferModal";

const AddressZero = "0x0000000000000000000000000000000000000000";

const ExploreDetails = (props) => {
  const { state } = useLocation();
  const [getauctionData, setAuctionData] = useState("");
  const [show, setShow] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [ownerNFT, setOwnerNFT] = useState("");
  const [highestBider, setHighestBider] = useState(0);
  const [highestBidAmount, setHighestBidAmount] = useState(0);
  const [details, setDetails] = useState({
    username: "@galaxi07",
    view: "3",
    likes: "3000",
    price: "1.000 ETH",
    img: category1,
    userProfile: user2,
  });

  const handleShow = (minPrice) => {
    setShow(true);
    setMinPrice(ethers.utils.formatEther(minPrice));
  };

  const handleBuyNow = async (nftContractAddress, tokenId, bidAmount) => {
    const bid = await makeBid(
      nftContractAddress,
      tokenId,
      bidAmount.toString()
    );
  };

  useEffect(async () => {
    setAuctionData(
      await getAuctionData(state.nftContractAddress, state.tokenId)
    );
    setOwnerNFT(await ownerOfNFT(state.nftContractAddress, state.tokenId));
  }, []);

  useEffect(() => {
    if (getauctionData && getauctionData.nftHighestBid) {
      setHighestBidAmount(getauctionData.nftHighestBid._hex);
      setHighestBider(getauctionData.nftHighestBidder);
    }
  }, [getauctionData]);

  const handleEtherscan = () => {
    if (window.ethereum.networkVersion == 1) {
      let eth = `https://etherscan.io/address/${state.nftContractAddress}`;
      window.open(eth);
    } else if (window.ethereum.networkVersion == 4) {
      let rin = `https://rinkeby.etherscan.io/address/${state.nftContractAddress}`;
      window.open(rin);
    }
  };
  return (
    <>
      <Header />
      {/* <section className="page-banner">
        <div className="container">
          <h2>Explore Item Details</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Item</li>
          </ul>
        </div>
      </section> */}

      <section className="sec explore-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="exp-detail-left">
                <div className="trending-cat-col">
                  {/* <span className="like">
                    <i className="fas fa-heart"></i> 10k
                  </span> */}
                  <div className="trending-cat-img">
                    <img src={details.img ? details.img : ""} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="exp-detail-right">
                <div className="ext-name-tit">
                  <h3>
                    {state.collectionName} #{state.tokenId}
                  </h3>
                  <div className="ext-name-tit-row">
                    <div className="det-top-left">
                      <span className="user-icon-l">
                        <img
                          src={details.userProfile ? details.userProfile : ""}
                          alt=""
                        />{" "}
                        <span className="user-check">
                          <img src="images/user-check.png" alt="" />
                        </span>
                      </span>
                      <span className="user-id">
                        {getauctionData &&
                        getauctionData.nftSeller != AddressZero
                          ? getauctionData.nftSeller
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ext-name-tit">
                  <div className="exp-btn-g">
                    <button
                      className="btn custom-btn wallet-btn"
                      onClick={handleEtherscan}
                    >
                      <span className="btn-icon">
                        <img src="images/etherscan-logo-circle.png" alt="" />
                      </span>{" "}
                      Etherscan
                    </button>
                  </div>
                  <div className="trending-cat-det">
                    <div className="det-top-right">
                      <span className="price">
                        {getauctionData && getauctionData.length
                          ? ethers.utils.formatEther(
                              getauctionData?.minPrice._hex
                            ) > 0
                            ? ethers.utils.formatEther(
                                getauctionData?.minPrice._hex
                              )
                            : ethers.utils.formatEther(
                                getauctionData?.buyNowPrice._hex
                              )
                          : ""}{" "}
                        <small>ETH</small> <span>Price</span>
                      </span>
                    </div>
                  </div>
                  <div className="exp-btn-g">
                    {getauctionData && getauctionData.minPrice > 0 ? (
                      <div className="exp-btn-g">
                        {" "}
                        <button
                          className="btn custom-btn wallet-btn"
                          onClick={() =>
                            handleBuyNow(
                              state.nftContractAddress,
                              state.tokenId,
                              minPrice
                            )
                          }
                        >
                          Buy Now
                        </button>
                        <button
                          className="btn custom-btn wallet-btn"
                          onClick={() =>
                            handleShow(
                              getauctionData && getauctionData.minPrice
                                ? getauctionData.minPrice._hex
                                : "",
                              state.nftContractAddress,
                              state.tokenId
                            )
                          }
                        >
                          Make Offer
                        </button>
                      </div>
                    ) : (
                      <button
                        className="btn custom-btn wallet-btn"
                        onClick={() =>
                          handleBuyNow(
                            state.nftContractAddress,
                            state.tokenId,
                            minPrice
                          )
                        }
                      >
                        Buy Now
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="exp-detail-left">
                {/* <ul className="accordion-list1"> */}
                <Accordion defaultActiveKey="1">
                  {/* <li className="active"> */}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="m-2">
                      <b>Descriptions</b>
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p>
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* </li> */}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="m-2">
                      <b>Details</b>
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p>Sed do eiusmod tempor incididunt ut labore et</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {/* </ul> */}
              </div>
            </div>

            {getauctionData && getauctionData.minPrice > 0 ? (
              <div className="col-md-6">
                <div className="exp-detail-right">
                  <span className="bid-histry">Highest Bid</span>
                  <div className="det-top-left bid-h-row">
                    <p>
                      <span className="user-id">
                        Placed a bid{" "}
                        {ethers.utils.formatEther(highestBidAmount)} by{" "}
                        {highestBider}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          {/* <ActivityList /> */}

          {/* <div className="collectible-details">
            <h2 className="sec-head">Collectible Details</h2>
            <p className="sub-head">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam.
            </p>

            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="collectin-img">
                  <img src={recent2 ? recent2 : ""} alt="" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="collectin-con">
                  <h4>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </h4>

                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr.
                  </p>

                  <p>
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore
                    magna aliquyam erat, sed diam voluptua. At vero eos et
                    accusam et justo duo dolores et ea rebum. Stet clita kasd
                    gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                    amet.
                  </p>
                  <br />
                  <a href="#" className="btn custom-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-center flex-row-reverse">
              <div className="col-md-5">
                <div className="collectin-img">
                  <img src={recent3 ? recent3 : ""} alt="" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="collectin-con">
                  <h4>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </h4>

                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr.
                  </p>

                  <p>
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore
                    magna aliquyam erat, sed diam voluptua. At vero eos et
                    accusam et justo duo dolores et ea rebum. Stet clita kasd
                    gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                    amet.
                  </p>
                  <br />
                  <a href="#" className="btn custom-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <MakeOfferModal
          show={show}
          setShow={setShow}
          minPrice={minPrice}
          nftContractAddress={state.nftContractAddress}
          tokenId={state.tokenId}
        />
      </section>

      <Faq />
      <Footer />
    </>
  );
};

export default ExploreDetails;

import React from "react";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import { login } from "../../slice/userSlice";
import { Modal, Button } from "react-bootstrap";
import { metamasklogo, walletconnectlogo } from "../../containers/assets";
import { signMessage } from "../../blockchain/wallet";

const ConnectWallet = (props) => {
  const { show, setShowPopup } = props;
  const dispatch = useDispatch();
  const signMessageHandler = async ({ setError, message }) => {
    try {
      // This string is 66 characters long

      // let messageBytes = utils.toUtf8Bytes(message);
      // This array representation is 32 bytes long

      const { address, signature, message } = await signMessage();
      dispatch(login({ message, signature, wallet_address: address }));
    } catch (err) {
      // setError(err.message);
      console.log("error==>", err);
    }
  };

  const handleSignInMetaMask = () => {
    signMessageHandler(
      "error",
      "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
    );
    setShowPopup(false);
  };

  const closeHandler = () => {
    setShowPopup(false);
  };

  return (
    <>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>
            <span className="wallet-popup-title">Connect your wallet</span>
          </Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="col-md-12">
              <div className="wallet-box">
                <ul className="wallet-list">
                  {/* <li className="active d-flex justify-content-between align-items-center">

                      <span className="metamask-popup">Walletconnect</span>{" "}
                      <span className="wallet-icon">
                        <img src={walletconnectlogo} alt="" />
                      </span>
                  </li> */}

                  <li
                    className="d-flex justify-content-between align-items-center"
                    onClick={handleSignInMetaMask}
                  >
                    {/* <h4 className="d-flex align-items-center"> */}
                    <span className="metamask-popup">Metamask</span>{" "}
                    <span className="wallet-icon">
                      <img src={metamasklogo} alt="" />
                    </span>
                    {/* </h4> */}
                  </li>
                </ul>
                {/* <a href="/" className="btn custom-btn wallet-btn">
                  <i className="fas fa-long-arrow-left"></i> Close
                </a> */}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => closeHandler()}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ConnectWallet;

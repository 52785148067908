import React, { useState } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { createCollection } from "../../slice/collectionSlice";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { apiConstants } from "../../constants/api.constants";
import { commonService } from "../../service/common.service";
import { deployContract } from "../../blockchain/collectionDeploy";
import TransactionModal from "../Common/TransactionModal";

const CreateCollection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState("initial")

  const [file, setFile] = useState("assets/images/default.png");
  const { collectionData } = useSelector((state) => {
    return { collectionData: state.collection };
  });
  const handleChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      external_link: "",
      blockchain: "Ethereum",
      logo: "",
      featured: "",
      banner: "",
      symbol: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please Enter name"),
      description: Yup.string().required("Please Enter description"),
      symbol: Yup.string()
        .required("please enter symbol")
        .test("len", "Must be exactly 5 characters", (val) => val.length <= 5)
        .matches(/^[A-Z]*$/, "Only Upper case"),
      external_link: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .required("Please enter external link"),
    }),

    onSubmit: async (values) => {
      let obj = {
        name: values.name,
        description: values.description,
        external_link: values.external_link,
        blockchain: "Ethereum",
        symbol: values.symbol,
        logo: values.logo,
        featured: values.featured,
        banner: values.banner,
      };

      await dispatch(createCollection(obj));

      const response = await commonService.withToken(
        apiConstants.GET_ALL_COLLECTIONS_BY_NAME,
        {
          name: obj.name,
        }
      );

      const collection = response.data.collection;

      if (typeof collection != null) {
        // await deployContract(collection);
        deployContract(collection).then(data=>{
          setStatus("complete")
          navigate("/collection");
          
        }).catch(error=>{
          console.log((error));
          setStatus("initial")
        })
      } else {
        alert("Something went wrong, couldn't mint the item");
      }
    },
  });

  const fileHandler = (e, name) => {
    formik.values[name] = e.target.files[0];
  };
  return (
    <>
    {
      (status === 'initial' || status === 'complete') ?
      <>
      <Header />
      <section className="page-banner">
        <div className="container">
          <h2>Collection</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Create Collection</li>
          </ul>
        </div>
      </section>
      <div>
        {/* <h1>Create a Collection</h1> */}
        <section className="sec explore-sec">
          <div className="container">
            <div className="profile-row d-center">
              <form onSubmit={formik.handleSubmit} style={{ width: "60%" }}>
                <p className="text-secondary">* Reqiured Fileds</p>
                <div className="login-form-feild">
                  <label>Logo image</label>
                  <p style={{ fontSize: "10px" }} className="text-secondary">
                    This image will also be used for navigation. 350 x 350
                    recommended.
                  </p>
                  <input
                    className="form-control"
                    type="file"
                    id="file"
                    name="logo"
                    onChange={(e) => fileHandler(e, "logo")}
                    onBlur={formik.handleBlur}
                    // style={{display:"none"}}
                  />
                  {/* <label for="file">
                  <img id="file" className="logoImg" src={file}  />
                  </label> */}

                  <label className="text-danger"></label>
                </div>
                <div className="login-form-feild">
                  <label>Featured image</label>
                  <p style={{ fontSize: "10px" }} className="text-secondary">
                    This image will be used for featuring your collection on the
                    homepage, category pages, or other promotional areas of 600
                    x 400 recommended.
                  </p>
                  <input
                    className="form-control feature-img"
                    type="file"
                    id="file"
                    name="featured"
                    onChange={(e) => fileHandler(e, "featured")}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="login-form-feild">
                  <label>Banner image</label>
                  <p style={{ fontSize: "10px" }} className="text-secondary">
                    This image will appear at the top of your collection page.
                    Avoid including too much text in this banner image, as the
                    dimensions change on different devices. 1400 x 400
                    recommended.
                  </p>
                  <input
                    className="form-control p-2"
                    type="file"
                    id="file-upload"
                    placeholder="passport"
                    name="banner"
                    onChange={(e) => fileHandler(e, "banner")}
                    onBlur={formik.handleBlur}
                  />

                  <label className="text-danger"></label>
                </div>
                <div className="login-form-feild">
                  <label>Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Example: Treasures"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label className="text-danger">
                    {formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : ""}
                  </label>
                </div>

                <div className="login-form-feild">
                  <label>External Link</label>
                  <p style={{ fontSize: "10px" }} className="text-secondary">
                    Customize your URL on. Must only contain lowercase letters,
                    numbers, and hyphens.
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    name="external_link"
                    alue={formik.values.external_link}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label className="text-danger"></label>
                </div>

                <div className="login-form-feild">
                  <label>Description</label>
                  <p style={{ fontSize: "10px" }} className="text-secondary">
                    Markdown syntax is supported. 0 of 1000 characters used.
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="description"
                    alue={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label className="text-danger"></label>
                </div>
                {/* <div className="login-form-feild">
                  <label>Blockchain</label>
                  <p style={{ fontSize: "10px" }} className="text-secondary">
                    Select the blockchain where you'd like new items from this
                    collection to be added by default.
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="blockchain"
                    alue={formik.values.blockchain}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div> */}
                <div className="login-form-feild">
                  <label>Symbol</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Symbol"
                    name="symbol"
                    alue={formik.values.symbol}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <label className="text-danger">
                  {formik.errors.symbol && formik.touched.symbol
                    ? formik.errors.symbol
                    : ""}
                </label>
                <div className="login-form-feild">
                  <button type="submit" className="btn custom-btn wallet-btn">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>

      <Footer /> </> : status === 'loadFunction' ? <TransactionModal /> : ""
    }
      
    </>
  );
};

export default CreateCollection;

import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConstants } from "../constants/api.constants";
import { setAlert } from "./alertSlice";
import { authentication } from "./authSlice";
import { commonService } from "../service/common.service";

const url = process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BASE_API;

export const createNftAuction = createAsyncThunk(
  apiConstants.CREATE_NFT_ACTION,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withToken(apiConstants.CREATE_NFT_ACTION, data)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setAlert({
              msg: "NFT Auction Created successfully",
              alertType: "success",
              timeout: 6000,
            })
          );
        }
        return response.data.success;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const getNftByCollectionName = createAsyncThunk(
  apiConstants.GET_NFT_BY_COLLECTION_NAME,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .getDataWithToken(apiConstants.GET_NFT_BY_COLLECTION_NAME, data)
      .then((response) => {
        if (response.data.success) {
          // dispatch(
          //   setAlert({
          //     msg: "Get NFT Collection successfully",
          //     alertType: "success",
          //     timeout: 6000,
          //   })
          // );
        }
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const getNftDetail = createAsyncThunk(
  apiConstants.GET_NFT_DETAIL,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .getDataWithToken(apiConstants.GET_NFT_DETAIL, data)
      .then((response) => {
        if (response.data.success) {
          // dispatch(
          //   setAlert({
          //     msg: "Get NFT successfully",
          //     alertType: "success",
          //     timeout: 6000,
          //   })
          // );
        }
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const getAllUnsoldNft = createAsyncThunk(
  apiConstants.GET_ALL_NFT_UNSOLD,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .getDataWithoutToken(apiConstants.GET_ALL_NFT_UNSOLD, data)
      .then((response) => {
        if (response.data.success) {
          // dispatch(
          //   setAlert({
          //     msg: "Get NFT successfully",
          //     alertType: "success",
          //     timeout: 6000,
          //   })
          // );
        }
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const updateNftDetail = createAsyncThunk(
  apiConstants.GET_ALL_NFT_UNSOLD,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withToken(apiConstants.GET_ALL_NFT_UNSOLD, data)
      .then((response) => {
        if (response.data.success) {
          // dispatch(
          //   setAlert({
          //     msg: "Get NFT successfully",
          //     alertType: "success",
          //     timeout: 6000,
          //   })
          // );
        }
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

const errorHandler = async (response) => {
  try {
    if (response) {
      let arr = response.data.errors.map((x, index) => {
        if (index !== response.data.errors.length) {
          return x.msg + ",";
        } else {
          return x.msg;
        }
      });
      return arr;
    }
  } catch (err) {
    console.log("======== Error in ErrorHandler user slice ========", err);
  }
};

const initialState = {
  value: "",
  status: "",
};

export const nftSlice = createSlice({
  name: "nft",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [createNftAuction.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
    [getNftByCollectionName.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
    [getNftDetail.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
    [getAllUnsoldNft.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
    [updateNftDetail.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "created";
    },
  },
});

export default nftSlice.reducer;

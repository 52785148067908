import React, { useState } from "react";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import { Modal, Button, InputGroup, Input, FormControl } from "react-bootstrap";
import { updateMinimumPrice } from "../../blockchain/marketplaceContract";

const UpdateMinPriceModal = (props) => {
  const {
    setUpdateMinPriceShow,
    updateMinPriceShow,
    tokenId,
    nftContractAddress,
  } = props;
  const [minPrice, setMinPrice] = useState("");
  const [error, setError] = useState(false);

  const submitHandler = async () => {
    setUpdateMinPriceShow(false);
    const updatedMinPrice = await updateMinimumPrice(
      nftContractAddress,
      tokenId,
      minPrice
    );
    console.log("out", updatedMinPrice);
    //TODO write function here for submit new min price
  };

  const handleClose = () => {
    setUpdateMinPriceShow(false);
  };
  return (
    <>
      <Modal show={updateMinPriceShow}>
        <Modal.Header>
          <Modal.Title>Update Min Price</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Enter New Min Price"
                aria-describedby="basic-addon2"
                onChange={(e) => setMinPrice(e.target.value)}
                value={minPrice}
              />
              <InputGroup.Text id="basic-addon2">ETH</InputGroup.Text>
            </InputGroup>

            {error ? (
              <label className="text-danger">
                Bid amount must be grater than current bid amount
              </label>
            ) : (
              ""
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => submitHandler()}>
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UpdateMinPriceModal;

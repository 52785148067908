import React, { useState } from "react";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import { Modal, Button, InputGroup,Input,FormControl } from "react-bootstrap";
import { makeBid } from "../../../blockchain/marketplaceContract";

const MakeOfferModal = (props) => {
  const { show,  setShow, minPrice, tokenId, nftContractAddress} = props;
  const [bidAmount, setBidAmount] = useState(0.0)
  const [error, setError] = useState(false)
 
  const submitHandler = async() => {
    setShow(false);
    if(bidAmount > minPrice){
        setError(false)
        const bid = await makeBid(
            nftContractAddress,
            tokenId,
            bidAmount
          );
    }else{
        setError(true)
    }
  };

  const handleSetBidAmount = (e) => {

    if(e.target.value < minPrice){
        setError(false)
    }else{
        setError(true)
    }
      setBidAmount(e.target.value)
  }

  const handleClose = () => {
      setShow(false)
  }
  return (
    <>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Make Offer</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            {/* <label className="bid-input" data-domain="ETH"> */}
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Enter Your Offer"
                aria-describedby="basic-addon2"
                onChange={handleSetBidAmount}
                value={bidAmount}
              />
              <InputGroup.Text id="basic-addon2">ETH</InputGroup.Text>
            </InputGroup>
           
            {error ? (
              <label className="text-danger">
                Bid amount must be grater than current bid amount
              </label>
            ) : (
              ""
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => submitHandler()}>
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default MakeOfferModal;

import { ethers, ContractFactory } from "ethers";
import { metaMaskClientCheck, connectWallet } from "./wallet";

import erc721 from "./NFT.json";
import { apiConstants } from "../constants/api.constants";
import { commonService } from "../service/common.service";
const NFTabi = erc721.abi;

const factory = new ContractFactory(erc721.abi, erc721.bytecode);

async function getNftContract(nftContractAddress) {
  const provider = metaMaskClientCheck();
  try {
    return new ethers.Contract(nftContractAddress, NFTabi, provider);
  } catch {
    // TODO: Add comething went wrong error message
  }
}

export async function deployContract(collectionDetails) {
  const provider = metaMaskClientCheck();

  const ERC721 = await factory.connect(provider.getSigner());
  try {
    console.log(collectionDetails);

    const contract = await ERC721.deploy(
      collectionDetails.name,
      collectionDetails.symbol,
      collectionDetails.metadata
    );

    const nftContract = await contract.deployed();
    const deployedAddress = nftContract.address;

    if (typeof nftContract != undefined) {
      await commonService.withToken(
        apiConstants.UPDATE_COLLECTION_DETALS_BYID,
        {
          id: collectionDetails._id,
          deployed: true,
          deployed_address: deployedAddress,
        }
      );
    } else {
      console.log(
        "Unable tod deploy the collection as NFT contract. Please try again"
      );
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

import React from "react";
import { homeBanner } from "../../../containers/assets";

const HomeBanner = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row flex-row-reverse align-items-center">
            <div className="col-md-12">
              <div>
                <img src={homeBanner} />
                {/* <video width="100%" height="468px" controls>
                  <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                  <source src="mov_bbb.ogg" type="video/ogg" />
                  Your browser does not support HTML video.
                </video> */}
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="banner-caps">
                <h1>COLLECT YOUR SUPER REAL EsTATE NFT</h1>
                <p>The world largest digital marketplace.</p>
                <ul>
                  <li>
                    2507 K
                    <span>Our Works</span>
                  </li>
                  <li>
                    1000 Items
                    <span>Live Auction</span>
                  </li>
                  <li>
                    505 M Usd
                    <span>Creator Earning</span>
                  </li>
                </ul>
                <p className="banner-btn">
                  <a href="#" className="btn custom-btn btn-fill">Explore</a>
                  <a href="#" className="btn v-btn"><i className="fab fa-youtube"></i> Learn more</a>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBanner;

import { createSlice, nanoid } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

const initialState = [];

export const alertSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    addAlert: (state, action) => {
      // modify the draft state and return nothing
      state.push(action.payload);
    },
    removeAlert: (state, action) => {
      // replace the entire slice state
      return state.filter((alert) => alert.id !== action.payload);
    },
  },
});

const { addAlert, removeAlert } = alertSlice.actions;
export const setAlert = createAsyncThunk(
  "/alert",
  async (data, { dispatch }) => {
    const id = nanoid();
    try {
      let obj = { msg: data.msg, alertType: data.alertType, id: id };
      dispatch(addAlert(obj));
      if (data.timeout) {
        // for timeout '0' setTimeout will not work
        setTimeout(() => dispatch(removeAlert(id)), data.timeout);
      }
    } catch (err) {
      console.log("======== Error in setAlert() =======", err);
    }
  }
);

export default alertSlice.reducer;

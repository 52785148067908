import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";

const Signup = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Please Enter username!"),
      password: Yup.string()
        .required("Please Enter Password")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          "Password should contain 1 special character ,1 number,1 uppercase ,1 lowercase"
        ),
      email: Yup.string()
        .required("Please Enter Email")
        .email("Must be a valid email"),
    }),

    onSubmit: (values) => {
      let obj = {
        username: values.username,
        email: values.email,
        password: values.password,
      };
    },
  });

  return (
    <>
      <Header />
      <section className="page-banner">
        <div className="container">
          <h2>Sign Up</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Sign up</li>
          </ul>
        </div>
      </section>

      <section className="sec explore-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="login-box login-img">
                <video width="100%" height="468px" controls>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  <source src="mov_bbb.ogg" type="video/ogg" />
                  Your browser does not support HTML video.
                </video>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login-box">
                <h2>Register Now</h2>
                <p className="log-with">
                  <a href="/" className="social-1">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="/" className="social-2">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="/" className="social-4">
                    <i className="fab fa-google"></i>
                  </a>
                </p>
                <p>or use your email</p>

                <div className="login-form">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="login-form-feild">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email here"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label className="text-danger">
                        {formik.errors.email && formik.touched.email
                          ? formik.errors.email
                          : ""}
                      </label>
                    </div>
                    <div className="login-form-feild">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="User name"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label className="text-danger">
                        {formik.errors.username && formik.touched.username
                          ? formik.errors.username
                          : ""}
                      </label>
                    </div>
                    <div className="login-form-feild">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label className="text-danger">
                        {formik.errors.password && formik.touched.password
                          ? formik.errors.password
                          : ""}
                      </label>
                    </div>

                    <div className="login-form-feild">
                      <button
                        type="submit"
                        className="btn custom-btn wallet-btn"
                      >
                        Sign up
                      </button>
                    </div>
                  </form>
                </div>

                <p>
                  Already have an account? <a href="/login">Log In!</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Signup;

import React, { useEffect, useState } from "react";
import Faq from "../Faq/Faq";
import { ethers } from "ethers";
import { Table, Button, Row, Col } from "react-bootstrap";

import { useLocation, useParams } from "react-router-dom";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Accordion from "react-bootstrap/Accordion";

import { category1, user2 } from "../../containers/assets";

import {
  settleAuction,
  withdrawAuction,
} from "../../blockchain/marketplaceContract";
import CreateSaleModal from "./CreateSaleModal";
import SaleAuctionModal from "./SaleAuctionModal";
import UpdateBuyNow from "./UpdateBuyNowModal";
import UpdateMinPriceModal from "./UpdateMinPriceModal";
import UpdateWhitelistedAddressModal from "./UpdateWhitelistedAddressModal";
import { getOwner } from "../../blockchain/itemMint";
import { useDispatch, useSelector } from "react-redux";
import { getItemsById } from "../../slice/itemSlice";

const ItemDetail = (props) => {
  const dispatch = useDispatch();
  const urlData = useParams();

  const [showAuction, setShowAuction] = useState(false);
  const [showSale, setShowSale] = useState(false);
  const [updateBuyNowShow, setUpdateBuyNowShow] = useState(false);
  const [updateMinPriceShow, setUpdateMinPriceShow] = useState(false);
  const [updateWhitelistedAddressShow, setUpdateWhitelistedAddressShow] =
    useState(false);
  const [item, setItem] = useState("");

  const [nftContractAddress, setNftContractAddress] = useState("");
  const [token, setToken] = useState("");
  const [details, setDetails] = useState({
    username: "@galaxi07",
    view: "3",
    likes: "3000",
    price: "1.000 ETH",
    img: category1,
    userProfile: user2,
  });
  const [metadata, setMetadata] = useState(null);
  const [image, setImage] = useState([]);
  const { itemData } = useSelector((state) => {
    return { itemData: state.item };
  });

  useEffect(() => {
    let obj = {
      itemId: urlData.id,
    };
    dispatch(getItemsById(obj));
  }, []);

  useEffect(() => {
    if (itemData && itemData.value && itemData?.value.item) {
      setItem(itemData.value.item);
    }
  }, [itemData]);

  useEffect(async () => {
    try {
      const metadata = await fetch(item.metadata);

      setMetadata(metadata);
    } catch (err) {
      console.log(err);

      if (item.image != null) {
        setImage(item.image);
      } else {
        setImage(details.img);
      }
    }
  }, []);

  useEffect(() => {
    let getOwnerFunction = async () => {
      await getOwner(item.contractAddress, item.tokenId);
    };
    getOwnerFunction().then((data) => {
      console.log(data);
    });
  }, []);

  const handleEtherscan = () => {
    if (window.ethereum.networkVersion == 1) {
      let eth = `https://etherscan.io/address/${item.contractAddress}`;
      window.open(eth);
    } else if (window.ethereum.networkVersion == 4) {
      let rin = `https://rinkeby.etherscan.io/address/${item.contractAddress}`;
      window.open(rin);
    }
  };

  const handleSaleClick = (nftContractAddress, tokenId) => {
    // setShowSale(true);
    // setNftContractAddress(nftContractAddress);
    // setToken(tokenId);
  };

  const handleWithdrawClick = async (nftContractAddress, tokenId) => {
    const withdrawData = await withdrawAuction(nftContractAddress, tokenId);
  };

  const handleSettleClick = async (nftContractAddress, tokenId) => {
    const settleData = await settleAuction(nftContractAddress, tokenId);
  };

  const handleTakeHighestBidClick = async (nftContractAddress, tokenId) => {
    const takeHighestBidData = await takeHighestBidData(
      nftContractAddress,
      tokenId
    );
  };

  const handleUpdateWhitelistedClick = async (nftContractAddress, tokenId) => {
    // setUpdateWhitelistedAddressShow(true);
    // setNftContractAddress(nftContractAddress);
    // setToken(tokenId);
  };

  const handleUpdateBuyNowClick = async (nftContractAddress, tokenId) => {
    setUpdateBuyNowShow(true);
    setNftContractAddress(nftContractAddress);
    setToken(tokenId);
  };

  const handleUpdateMinClick = async (nftContractAddress, tokenId) => {
    setNftContractAddress(nftContractAddress);
    setToken(tokenId);
    setUpdateMinPriceShow(true);
  };

  const handleAuctionClick = async (nftContractAddress, tokenId) => {
    setNftContractAddress(nftContractAddress);
    setToken(tokenId);
    setShowAuction(true);
  };

  if (!item) return <></>;

  return (
    <>
      <Header />
      {/* <section className="page-banner">
        <div className="container">
          <h2>Explore Item Details</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Item</li>
          </ul>
        </div>
      </section> */}

      <section className="sec explore-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="exp-detail-left">
                <div className="trending-cat-col">
                  {/* <span className="like">
                    <i className="fas fa-heart"></i> 10k
                  </span> */}
                  <div className="trending-cat-img">
                    <img
                      src={item != null ? item?.image : image}
                      width="300"
                      height="300"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="exp-detail-right">
                <div className="ext-name-tit">
                  <h3>
                    {item?.name}
                    {item?.tokenId ? <p> #{item?.tokenId}</p> : ""}
                  </h3>
                  <div className="ext-name-tit-row">
                    <div className="det-top-left">
                      <span className="user-icon-l">
                        <span className="user-check">
                          <img src="images/user-check.png" alt="" />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ext-name-tit">
                  <div className="exp-btn-g">
                    <button
                      className="btn custom-btn wallet-btn"
                      onClick={handleEtherscan}
                    >
                      <span className="btn-icon">
                        <img src="images/etherscan-logo-circle.png" alt="" />
                      </span>{" "}
                      Etherscan
                    </button>
                  </div>
                </div>
                {item?.minted ? (
                  <div className="exp-btn-g">
                    <Row>
                      <Col>
                        <Button
                          variant="primary"
                          style={{ margin: "5px" }}
                          onClick={() =>
                            handleSaleClick(
                              item?.contractAddress,
                              item?.tokenId
                            )
                          }
                        >
                          Create Sale
                        </Button>

                        <Button
                          variant="primary"
                          onClick={() =>
                            handleAuctionClick(
                              item?.contractAddress,
                              item?.tokenId
                            )
                          }
                        >
                          Create Auction
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="font-weight-bold">End Functions</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          variant="primary"
                          style={{ margin: "5px" }}
                          onClick={() =>
                            handleWithdrawClick(
                              item?.contractAddress,
                              item?.tokenId
                            )
                          }
                        >
                          Withdraw Listing
                        </Button>

                        <Button
                          variant="primary"
                          style={{ margin: "5px" }}
                          onClick={() =>
                            handleSettleClick(
                              item?.contractAddress,
                              item?.tokenId
                            )
                          }
                        >
                          Settle Listing
                        </Button>

                        <Button
                          variant="primary"
                          style={{ margin: "5px" }}
                          onClick={() =>
                            handleTakeHighestBidClick(
                              item?.contractAddress,
                              item?.tokenId
                            )
                          }
                        >
                          Take Highest Bid
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="font-weight-bold">
                          Update Functions
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          variant="primary"
                          style={{ margin: "5px" }}
                          onClick={() =>
                            handleUpdateWhitelistedClick(
                              item?.contractAddress,
                              item?.tokenId
                            )
                          }
                        >
                          Update Whitelisted Buyer
                        </Button>

                        <Button
                          variant="primary"
                          style={{ margin: "5px" }}
                          onClick={() =>
                            handleUpdateBuyNowClick(
                              item?.contractAddress,
                              item?.tokenId
                            )
                          }
                        >
                          Update Buy Now Price
                        </Button>

                        <Button
                          variant="primary"
                          style={{ margin: "5px" }}
                          onClick={() =>
                            handleUpdateMinClick(
                              item?.contractAddress,
                              item?.tokenId
                            )
                          }
                        >
                          Update Min Price
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="exp-detail-left">
                {/* <ul className="accordion-list1"> */}
                <Accordion defaultActiveKey="1">
                  {/* <li className="active"> */}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="m-2">
                      <b>Descriptions</b>
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p>{item?.description}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* </li> */}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="m-2">
                      <b>Details</b>
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p>Minted: {item?.minted ? "True" : "False"}</p>
                      {item?.tokenId ? <p>Token ID: {item?.tokenId}</p> : ""}
                      {item?.creator ? <p>Creator: {item?.creator}</p> : ""}
                      {item?.contractAddress ? (
                        <p>NFT Contract Address: {item?.contractAddress}</p>
                      ) : (
                        ""
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {/* </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Faq />
      <Footer />
      <CreateSaleModal
        showSale={showSale}
        setShowSale={setShowSale}
        nftContractAddress={nftContractAddress}
        tokenId={token}
      />
      <SaleAuctionModal
        showAuction={showAuction}
        setShowAuction={setShowAuction}
        nftContractAddress={nftContractAddress}
        tokenId={token}
      />
      <UpdateBuyNow
        setUpdateBuyNowShow={setUpdateBuyNowShow}
        updateBuyNowShow={updateBuyNowShow}
        nftContractAddress={nftContractAddress}
        tokenId={token}
      />
      <UpdateMinPriceModal
        setUpdateMinPriceShow={setUpdateMinPriceShow}
        updateMinPriceShow={updateMinPriceShow}
        nftContractAddress={nftContractAddress}
        tokenId={token}
      />
      <UpdateWhitelistedAddressModal
        setUpdateWhitelistedAddressShow={setUpdateWhitelistedAddressShow}
        updateWhitelistedAddressShow={updateWhitelistedAddressShow}
        nftContractAddress={nftContractAddress}
        tokenId={token}
      />
    </>
  );
};

export default ItemDetail;

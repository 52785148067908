import React, { useEffect, useState } from "react";
import { profile } from "../../containers/assets";
import FilterList from "../Home/ExploreTimeLine/FilterList";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Faq from "../Faq/Faq";
import { Dropdown, Spinner } from "react-bootstrap";
import EditPopup from "../Common/Popup/EditPopup";
import { updateProfile, getProfile } from "../../slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import TransactionModal from "../Common/TransactionModal";
import axios from "axios";
import { apiConstants } from "../../constants/api.constants";
import ReactPaginate from "react-paginate";
import { deployContract } from "../../blockchain/collectionDeploy";
import { commonService } from "../../service/common.service";

const ProfileCollectionOwner = () => {
  let urlParam = useParams();
  const [profileDetails, setProfileDetails] = useState();
  const navigate = useNavigate();

  // const [profileWalletAddress, setProfileWalletAddress] = useState('')
  const [collections, setCollectionData] = useState('')
  const [pageNo, setPageNumber] = useState(0)
  const [show, setShow] = useState(false);
  const [sessionData, setSessionData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const [showPopup, setShowPopup] = useState(false);
  const [search, setSearch] = useState('')
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => {
    return { profileData: state.user };
  });
  const url =
    process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BASE_API;

  useEffect(async () => {
    let result = await axios.post(
      `${url + apiConstants.GET_PROFILE_WALLET_ADDRESS}`,
      { wallet_address: urlParam.id }
    );
    let obj = {
      search: "",
      offset: pageNo,
      creator: urlParam.id
    }
    let collectionList = await axios.post(`${url + apiConstants.GET_ALL_COLLECTIONS_BY_CREATOR}?search=${search}`, obj)

    setCollectionData(collectionList.data.collections)
    setPageNumber(collectionList.data.pages)
    setProfileDetails(result.data.user);
  }, []);

  useEffect(async () => {
    let obj = {
      offset: pageNo,
      creator: urlParam.id

    };
    let collectionList = await axios.post(`${url + apiConstants.GET_ALL_COLLECTIONS_BY_CREATOR}?search=${search}`, obj)
    setCollectionData(collectionList.data.collections)
    setPageNumber(collectionList.data.pages)
  }, [search])

  const addDefaultSrc = (ev) => {
    console.log(ev);
    ev.target.src = profile;
  };

  // const handleDeploy = async (_id) => {
  //   const response = await commonService.withOutToken(
  //     apiConstants.GET_COLLECTION_BY_ID,
  //     {
  //       id: _id,
  //     }
  //   );

  //   const collection = response.data.collection;

  //   if (typeof collection != null) {
  //     await deployContract(collection);
  //   } else {
  //     alert("Something went wrong, couldn't mint the item");
  //   }
  // };

  const handlePageClick = async (pageno) => {
    let obj = {
      offset: pageno.selected,
      creator: urlParam.id

    };
    let collectionList = await axios.post(`${url + apiConstants.GET_ALL_COLLECTIONS_BY_CREATOR}?search=${search}`, obj)
    setCollectionData(collectionList.data.collections)
    setPageNumber(collectionList.data.pages)
  };
  console.log(profileDetails, collections);
  // if(!profileDetails) return <></>

  return (
    <>
      <Header />
      <section className="page-banner">
        <div className="container">
          <h2>Profile</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Profile</li>
          </ul>
        </div>
      </section>

      <div className="shadow p-3 mb-5 bg-white rounded">
        {/* kyc Status display here ... */}
      </div>
      <section className="sec explore-sec">
        <div className="container">
          <div className="profile-row">
            <div className="profile-col-left">
              <div className="profile-img-l">
                <img
                  onError={addDefaultSrc}
                  src={
                    profileDetails && profileDetails.profile_image
                      ? profileDetails.profile_image
                      : ""
                  }
                  width="300"
                  height="200"
                  alt=""
                />
              </div>
              <div className="profile-detail-l">
                <h4>
                  {profileDetails && profileDetails.name
                    ? profileDetails.name
                    : ""}{" "}
                  <span>
                    <img src="images/check-icon-b.png" alt="" />
                  </span>
                </h4>
                <p className="prof-seb">
                  {profileDetails && profileDetails.description
                    ? profileDetails.wallet_address
                    : ""}
                </p>
                <p>
                  Bio: <br />
                  {profileDetails && profileDetails.description
                    ? profileDetails.description
                    : ""}{" "}
                </p>
                {/* <p className="prof-btn-group">
                  <Link to="/upload-kyc" className="prof-btn m-2">
                    {" "}
                    Upload KYC{" "}
                  </Link>
                </p> */}
              </div>
            </div>
            <div class="profile-col-right">
              <div class="footer-right">
                {profileDetails?.twitter && profileDetails?.twitter != "" ? (
                  <a
                    href={profileDetails?.twitter}
                    target="_blank"
                    class="social-4"
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                ) : (
                  <></>
                )}
                {profileDetails?.facebook && profileDetails?.facebook != "" ? (
                  <a
                    href={profileDetails?.facebook}
                    target="_blank"
                    class="social-4"
                  >
                    <i class="fab fa-facebook"></i>
                  </a>
                ) : (
                  <></>
                )}
                {profileDetails?.instagram &&
                  profileDetails?.instagram != "" ? (
                  <a
                    href={profileDetails?.instagram}
                    target="_blank"
                    class="social-4"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                ) : (
                  <></>
                )}
                {profileDetails?.linkedin?.value &&
                  profileDetails?.linkedin?.value != "" ? (
                  <a
                    href={profileDetails?.linkedin}
                    target="_blank"
                    class="social-4"
                  >
                    <i class="fab fa-linkedin"></i>
                  </a>
                ) : (
                  <></>
                )}
                {profileDetails?.youtube && profileDetails?.youtube != "" ? (
                  <a
                    href={profileDetails?.youtube}
                    target="_blank"
                    class="social-4"
                  >
                    <i class="fab fa-youtube"></i>
                  </a>
                ) : (
                  <></>
                )}
                {profileDetails?.discord && profileDetails?.discord != "" ? (
                  <a
                    href={profileDetails?.discord}
                    target="_blank"
                    class="social-4"
                  >
                    <i class="fab fa-discord"></i>
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <TransactionModal /> */}
      {
        collections && collections.length ? <section className="sec explore-sec">
          <div className="container">
            {" "}
            {/* <div className="row">
            <div className="col-md-6 text-secondary">
              {itemData?.value.items.length} items
            </div>
          </div> */}
          </div>
          <div className="container">
            {/* <div className="d-right">
            <div className="filter-form">
              <div className="filter-form-feild"></div>
              <div className="filter-form-feild">
                <input
                  type="text"
                  name="search"
                  className="form-control"
                  placeholder="Search"
                  // onChange={handleSearch}
                  onChange={(e) => setSearch(e.target.value)}

                />
                <button type="button">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div> */}
            <div className="item-row">
              <div className="row">
                {collections && collections.length > 0 ? (
                  collections.map((x, index) => {
                    return (
                      <div
                        className="col-sm-6 col-md-6 col-lg-6 col-xl-4"
                        key={index}
                      >
                        <div className="trending-cat-col">
                          <div className="trending-cat-img">
                            <img
                              onError={addDefaultSrc}
                              src={x.logo_image ? x.logo_image : x.logo_image}
                              alt=""
                              onClick={() =>
                                navigate(
                                  `/explore-collection-details/${x._id}`
                                )
                              }
                            />
                          </div>
                          <div className="trending-cat-det">
                            <div className="det-top">
                              <div
                                className="det-top-left"
                                onClick={() =>
                                  navigate(`/collectionDetails/${x._id}`)
                                }
                              >
                                <span className="pro-name">
                                  {x && x.name
                                    ? x.name.charAt(0).toUpperCase() +
                                    x.name.slice(1)
                                    : ""}
                                </span>
                              </div>

                              {/* <div className="det-top-right">
                              {x.deployed ? (
                                <span style={{ color: "green" }}>Deployed</span>
                              ) : (
                                <div onClick={() => handleDeploy(x._id)}>
                                  {" "}
                                  <button class="btn" style={{ color: "red" }}>
                                    Deployed
                                  </button>{" "}
                                </div>
                              )}
                            </div> */}
                            </div>
                            <div className="text-center">
                              <Link
                                to={`/collectionDetails/${x._id}`}
                                className="btn custom-btn wallet-btn"
                              >
                                Details
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center">
                    Record Not found
                  </div>
                )}
              </div>
              <div className="d-center">
                <ReactPaginate
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageNo}
                  previousLabel="< previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </section> : ""
      }

      <Footer />
    </>
  );
};

export default ProfileCollectionOwner;

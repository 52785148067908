import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";

const Faq = () => {
  const faq = [
    {
      ques: "What wallet will I need to purchase NFT?",
      answer:
        "You will need to use a non-custodial wallet. At the moment, MetaMask is supported by New Wealth Marketplace. You must have the amount of ETH required to purchase the NFT plus a little extra to cover gas fees.",
    },
    {
      ques: "What is a Floor Price?",
      answer:
        "A Floor Price is the lowest price for a collection. It gives you a quick one-time overview of your lowest selling item in that collection. It can be used to make quick assessments.",
    },
    {
      ques: "What are gas fees?",
      answer:
        "Gas fees are the transaction fees paid to run on the Ethereum network. These fees are distributed to the miners. New Wealth does not receive any of the gas fees. ",
    },
    {
      ques: "What crypto currency do you accept?",
      answer:
        "Currently, New Wealth Marketplace is accepting only Ethereum for the buying and selling of NFTs.",
    },
  ];

  return (
    <section className="sec trending-cat">
      <div className="container">
        <h2 className="sec-head">Frequently Asked Questions</h2>
        <p className="sub-head">
          Below you will find the most common questions to get you started.
        </p>

        <Accordion>
          {faq && faq.length > 0
            ? faq.map((x, index) => {
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header className="m-2">
                      <b>{x.ques}</b>
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p>{x.answer}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })
            : ""}
        </Accordion>
      </div>
    </section>
  );
};

export default Faq;

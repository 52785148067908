import React, { useEffect, Fragment } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Router, Switch } from "react-router-dom";

import App from "./components/Home/Home";
import "./assets/css/all.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/custom.css";
import "./assets/css/responsive.css";
// import history from './_helper';

import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import ConnectWallet from "./components/ConnectWallet/ConnectWallet";
import ExploreDetails from "./components/Home/ExploreTimeLine/ExploreDetails";
import ExploreList from "./components/Home/ExploreTimeLine/ExploreList";
import AuthRoute from "./components/Layout/AuthRoute";
import Profile from "./components/Users/Profile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import NotFound from "./components/NotFound/NotFound";
import UploadKyc from "./components/Users/UploadKyc";
import CreateCollection from "./components/Collection/CreateCollection";
import CreateItem from "./components/Item/CreateItem";
import CollectionList from "./components/Collection/CollectionList";
import ExploreCollectionList from "./components/ExploreCollection/ExploreCollectionList";

import ItemList from "./components/Item/ItemList";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import ItemDetail from "./components/Item/ItemDetail";
import CollectionDetail from "./components/Collection/CollectionDetail";
import CollectionItemDetails from "./components/Collection/CollectionItemDetails";
import ExploreMintingList from "./components/ExploreMinting/ExploreMintingList";
import ExploreCollectionDetail from "./components/ExploreCollection/ExploreCollectionDetail";
import ExploreCollectionItemDetails from "./components/ExploreCollection/ExploreCollectionItemDetails";
import ProfileCollectionOwner from "./components/Users/ProfileCollectionOwner";
const { detect } = require("detect-browser");

const browser = detect();
const MainApp = () => {
  const { auth, alerts } = useSelector((state) => {
    return { auth: state.auth, alerts: state.alerts };
  });
  console.log(auth);
  useEffect(() => {
    if (alerts && Object.keys(alerts).length > 0) {
      alerts.map((x, index) => {
        return toast[x.alertType](x.msg);
      });
    }
  }, [alerts]);

  return (
    <>
      <BrowserRouter>
        <Fragment>
          <ToastContainer
            limit={1}
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="colored"
          />
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route exact path="/Collections" element={<CollectionList />} />
              <Route exact path="/Items" element={<ItemList />} />
              <Route exact path="/Item-detail/:id" element={<ItemDetail />} />

              <Route exact path="/Create-Item" element={<CreateItem />} />
              <Route exact path="/profile" element={<Profile />} />

              <Route exact path="/upload-kyc" element={<UploadKyc />} />
              {/* <Route exact path="/explore-minting" element={<ExploreMintingList />} /> */}

              <Route
                exact
                path="itemDetails/:itemId"
                element={<ItemDetail />}
              />

              <Route
                exact
                path="/Create-Collection"
                element={<CreateCollection />}
              />
              <Route
                exact
                path="/Collection-item-details/:id"
                element={<CollectionItemDetails />}
              />
              {/* <Route exact path="/explore-list" element={<ExploreMintingList />} /> */}
            </Route>
            <Route exact path="/" element={<ExploreCollectionList />} />
            <Route
              exact
              path="/explore-collection-details/:id"
              element={<ExploreCollectionDetail />}
            />
            <Route
              exact
              path="/explore-collection-item-detail/:id"
              element={<ExploreCollectionItemDetails />}
            />
            <Route
              exact
              path="/profileCollection/:id"
              element={<ProfileCollectionOwner />}
            />

            <Route exact path="/explore-details" element={<ExploreDetails />} />
            <Route exact path="/" element={<App />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/connect-wallet" element={<ConnectWallet />} />
            <Route exact path="*" element={<NotFound />} />
            <Route
              exact
              path="collectionDetails/:id"
              element={<CollectionDetail />}
            />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </>
  );
};

export default MainApp;

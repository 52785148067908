import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProfile, subscribeUser } from "../../../slice/userSlice";

const Footer = () => {
  const dispatch = useDispatch();
  const [profileDetails, setProfileDetails] = useState("");
  const [email, setEmail] = useState("");
  const { profileData } = useSelector((state) => {
    return { profileData: state.user };
  });
  const handleSubmit = () => {
    let obj = {
      email,
    };
    dispatch(subscribeUser(obj));
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (
      profileData &&
      profileData.value != undefined &&
      profileData.value != ""
    ) {
      setProfileDetails(profileData.value);
    }
  }, [profileData]);

  console.log(profileDetails);
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <h4>Company</h4>
                  <ul>
                    <li>
                      <a href="/explore-list">Explore</a>
                    </li>
                    <li>
                      <a href="/">Library</a>
                    </li>
                    <li>
                      <a href="/">Activity</a>
                    </li>
                    <li>
                      <a href="/">About</a>
                    </li>
                    {/* <li>
                      <a href="/">Faq</a>
                    </li> */}
                  </ul>
                </div>
                <div className="col-md-6 col-lg-3">
                  <h4>Resources</h4>
                  <ul>
                    <li>
                      <a
                        href="https://www.newwealthcolab.com/terms-of-use"
                        target="_blank"
                      >
                        Terms of Service
                      </a>
                    </li>
                    <li>
                      <a href="/">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <h4>Stay Informed By Newsletter</h4>
              <p>
                Subscribe to our newsletter to receive latest and most up to
                date information about New Wealth Colab Marketplace.
              </p>

              <div className="subscribe-box">
                <input
                  type="text"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  className="btn custom-btn"
                  onClick={handleSubmit}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="footer-left">
              <a href="/" className="footer-logo">
                <img src="images/footer-logo.png" alt="" />
              </a>
              <span>
                &copy; <strong>2022 NEWWEALTHCOLAB </strong>All rights reserved.
              </span>
            </div>
            <div className="footer-right">
              <a href={profileDetails?.twitter?.value} class="social-1">
                <i class="fab fa-twitter"></i>
              </a>
              <a href={profileDetails?.facebook?.value} class="social-2">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href={profileDetails?.instagram?.value} class="social-3">
                <i class="fab fa-instagram"></i>
              </a>
              <a href={profileDetails?.Linkedin?.value} class="social-4">
                <i class="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { createItem } from "../../slice/itemSlice";
import { getAllCollectionsByCreator } from "../../slice/collectionSlice";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { mintItem } from "../../blockchain/itemMint";
import { apiConstants } from "../../constants/api.constants";
import { commonService } from "../../service/common.service";
import { setAlert } from "../../slice/alertSlice";
import TransactionModal from "../Common/TransactionModal";

const CreateItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [file, setFile] = useState("assets/images/default.png");
 const [status, setStatus] = useState("initial")
  const { collectionData } = useSelector((state) => {
    return { collectionData: state.collection };
  });

  useEffect(() => {
    dispatch(getAllCollectionsByCreator());
  }, []);

  const handleChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const onSelect = (selectedList, selectedItem) => {
  };

  const onRemove = (selectedList, removedItem) => {};

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      external_link: "",
      blockchain: "Ethereum",
      collectionId: "",
      image: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please Enter name"),
      description: Yup.string().required("Please Enter description"),
      external_link:Yup.string()
      .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Enter correct url!'
      )
      .required('Please enter external link')
      // collectionId: Yup.string().required()
    }),

    onSubmit: async (values) => {
      setStatus("loadFunction")
      let obj = {
        name: values.name,
        description: values.description,
        external_link: values.external_link,
        blockchain: "Ethereum",
        collectionId: values.collectionId,
        image: values.image,
      };

      await dispatch(createItem(obj));

      const item = await commonService.withToken(
        apiConstants.GET_ITEM_DETAIL_BYNAME,
        {
          name: obj.name,
        }
      );
      if (typeof item.data.items != null) {
        // const i = await mintItem(item.data.items);
        mintItem(item.data.items).then(data=>{
          setStatus("complete")
          navigate("/items");
          
        }).catch(error=>{
          console.log((error));
          setStatus("initial")
        })
      } else {
        alert("Something went wrong, couldn't mint the item");
      }
    },
  });

  const fileHandler = (e, name) => {
    formik.values[name] = e.target.files[0];
  };
  return (
    <>
     { (status === 'initial' || status === 'complete') ? <><Header />
      <section className="page-banner">
        <div className="container">
          <h2>Item</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Create Item</li>
          </ul>
        </div>
      </section>
      <div>
        {/* <h1>Create a Collection</h1> */}
        <section className="sec explore-sec">
          <div className="container">
            <div className="profile-row d-center">
              <form onSubmit={formik.handleSubmit} style={{ width: "60%" }}>
                <p className="text-secondary">* Reqiured Fileds</p>

                <div className="login-form-feild">
                  <label>Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Example: Treasures of the Newwealth"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label className="text-danger">
                    {formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : ""}
                  </label>
                </div>

                <div className="login-form-feild">
                  <label>External link</label>
                  <p style={{ fontSize: "10px" }} className="text-secondary">
                    Customize your URL on Newwealth.
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    name="external_link"
                    alue={formik.values.external_link}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label className="text-danger">
                    {formik.errors.external_link && formik.touched.external_link
                      ? formik.errors.external_link
                      : ""}
                  </label>
                </div>

                <div className="login-form-feild">
                  <label>Description</label>
                  <p style={{ fontSize: "10px" }} className="text-secondary">
                    Markdown syntax is supported. 0 of 1000 characters used.
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="description"
                    alue={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label className="text-danger"></label>
                </div>
                <div className="login-form-feild">
                  <label>Collection Id</label>
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="collectionId"
                    alue={formik.values.collectionId} onChange={formik.handleChange} onBlur={formik.handleBlur}
                  /> */}
                  <select
                    className="form-control"
                    name="collectionId"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ height: "50px" }}
                  >
                    <option>Please select Collection Id</option>
                    {(
                      (collectionData && collectionData.value.collections) ||
                      []
                    ).map((data) => {
                      return (
                        <>
                          {data.deployed === true ? (
                            <option value={data._id}>{data.name}</option>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </select>
                  <label className="text-danger">
                    {formik.errors.collectionId && formik.touched.collectionId
                      ? formik.errors.collectionId
                      : ""}
                  </label>
                </div>

                <div className="login-form-feild">
                  <label>Media File</label>
                  <p style={{ fontSize: "10px" }} className="text-secondary">
                    This image will be used for featuring your collection on the
                    homepage, category pages, or other promotional areas of
                    Newwealth. 600 x 400 recommended.
                  </p>
                  <input
                    className="form-control feature-img"
                    type="file"
                    id="file"
                    name="image"
                    onChange={(e) => fileHandler(e, "image")}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div className="login-form-feild">
                  <button type="submit" className="btn custom-btn wallet-btn">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>

      <Footer /> </> : status === 'loadFunction' ? <TransactionModal /> : ""}
    </>
  );
};

export default CreateItem;

import React, { useEffect, useState } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import { category1 } from "../../containers/assets";

import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { getAllCollectionsByCreator } from "../../slice/collectionSlice";
import * as Yup from "yup";
import { mintItem } from "../../blockchain/itemMint";
import { apiConstants } from "../../constants/api.constants";
import { commonService } from "../../service/common.service";
import { deployContract } from "../../blockchain/collectionDeploy";

const CollectionList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentno, setCurrentPageNo] = useState(0);

  const [filteredCollection, setFilteredCollection] = useState([]);
  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);

  const { collectionData } = useSelector((state) => {
    return { collectionData: state.collection };
  });

  useEffect(() => {
    let obj = {
      offset: 0,
      search: "",
    };
    dispatch(getAllCollectionsByCreator(obj));
  }, []);

  useEffect(() => {
    if (collectionData && collectionData?.value.collections) {
      setList(collectionData.value.collections);
      setFilteredCollection(collectionData.value.collections);
    }
  }, [collectionData]);

  const handleChange = (e) => {
    let obj = {
      offset: 0,
      search: e.target.value,
    };
    dispatch(getAllCollectionsByCreator(obj));
  };

  const handleClick = () => {
    navigate("/create-collection");
  };

  const handleDeploy = async (_id) => {
    const response = await commonService.withToken(
      apiConstants.GET_COLLECTION_BY_ID,
      {
        id: _id,
      }
    );

    const collection = response.data.collection;

    if (typeof collection != null) {
      await deployContract(collection);
    } else {
      alert("Something went wrong, couldn't mint the item");
    }
  };

  const handlePageClick = (event) => {
    setCurrentPageNo(event.selected - 1);
    let obj = {
      offset: event.selected,
    };
    dispatch(getAllCollectionsByCreator(obj));
  };

  return (
    <>
      <Header />
      <section className="page-banner">
        <div className="container">
          <h2>Collection</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Collection List</li>
          </ul>
        </div>
      </section>
      <section className="sec explore-sec">
        <div className="container">
          <div className="d-right">
            <div className="filter-form">
              <div className="filter-form-feild">
                <input
                  type="text"
                  name="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleChange}
                />
                <button type="button">
                  <i className="fa fa-search"></i>
                </button>
              </div>
              {/* <button
                className="btn custom-btn wallet-btn"
                onClick={handleClick}
              >
                Create Collection
              </button> */}
            </div>
          </div>
          <div className="item-row">
            <div className="row">
              {filteredCollection && filteredCollection.length > 0 ? (
                filteredCollection.map((x, index) => {
                  return (
                    <div
                      className="col-sm-6 col-md-6 col-lg-6 col-xl-4"
                      key={index}
                    >
                      <div className="trending-cat-col">
                        {/* <span className="like">
                            <i className="fas fa-heart"></i>{" "}
                            {x && x.like ? x.like : ""}
                          </span> */}
                        <div className="trending-cat-img">
                          <img
                            src={x && x.logo_image ? x.logo_image : category1}
                            alt=""
                            onClick={() =>
                              navigate(`/collectionDetails/${x._id}`)
                            }
                          />
                        </div>
                        <div className="trending-cat-det">
                          <div className="det-top">
                            <div
                              className="det-top-left"
                              onClick={() =>
                                navigate(`/collectionDetails/${x._id}`)
                              }
                            >
                              <span className="pro-name">
                                {x && x.name
                                  ? x.name.charAt(0).toUpperCase() +
                                    x.name.slice(1)
                                  : ""}
                              </span>
                            </div>

                            <div className="det-top-right">
                              {x.deployed ? (
                                <span style={{ color: "green" }}>Deployed</span>
                              ) : (
                                <div onClick={() => handleDeploy(x._id)}>
                                  {" "}
                                  <button class="btn" style={{ color: "red" }}>
                                    Deployed
                                  </button>{" "}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-center">
                            <Link
                              to={`/collectionDetails/${x._id}`}
                              className="btn custom-btn wallet-btn"
                            >
                              Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center">
                  Record Not found
                </div>
              )}
            </div>
            <div className="d-center">
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={collectionData.value.pages}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default CollectionList;

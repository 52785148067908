import React, { useEffect, useState } from "react";
import Faq from "../Faq/Faq";
import { ethers } from "ethers";

import { useLocation, useParams } from "react-router-dom";

// import ActivityList from "../RecentActivity/ActivityList";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Accordion from "react-bootstrap/Accordion";
import { category1, user2 } from "../../containers/assets";
import { getItemsById } from "../../slice/itemSlice";
import { useDispatch, useSelector } from "react-redux";

const CollectionItemDetails = (props) => {
  const [getauctionData, setAuctionData] = useState("");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [highestBider, setHighestBider] = useState(0);
  const [highestBidAmount, setHighestBidAmount] = useState(0);
  const [item, setItem] = useState("");
  const urlData = useParams();

  const { itemData } = useSelector((state) => {
    return { itemData: state.item };
  });

  useEffect(() => {
    let obj = {
      itemId: urlData.id,
    };
    dispatch(getItemsById(obj));
  }, []);
  console.log(itemData);
  useEffect(() => {
    if (itemData && itemData.value && itemData.value.item) {
      setItem(itemData.value.item);
    }
  }, [itemData]);

  if (!item) return <></>;
  return (
    <>
      <Header />
      {/* <section className="page-banner">
        <div className="container">
          <h2>Item Details</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Item</li>
          </ul>
        </div>
      </section> */}

      <section className="sec explore-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="exp-detail-left">
                <div className="trending-cat-col">
                  <div className="trending-cat-img-detail">
                    <img src={item.image ? item.image : ""} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="exp-detail-right">
                <div className="ext-name-tit">
                  <h4>{item.name}</h4>

                  <h3>
                    {item.name} #{item.tokenId}
                  </h3>
                  <div className="ext-name-tit-row">
                    <div className="det-top-left"></div>
                  </div>
                </div>
                {/* <div className="ext-name-tit">
                  <div className="trending-cat-det">
                    <div className="det-top-right"></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="exp-detail-left">
                {/* <ul className="accordion-list1"> */}
                <Accordion defaultActiveKey="1">
                  {/* <li className="active"> */}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="m-2">
                      <b>Descriptions</b>
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p>{item.description}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* </li> */}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="m-2">
                      <b>Details</b>
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p>{item.description}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {/* </ul> */}
              </div>
            </div>

            {getauctionData && getauctionData.minPrice > 0 ? (
              <div className="col-md-6">
                <div className="exp-detail-right">
                  {/* <a href="#" className="bid-histry"> */}
                  <span className="bid-histry">Highest Bid</span>
                  {/* </a> */}
                  <div className="det-top-left bid-h-row">
                    <p>
                      <span className="user-id">
                        Placed a bid{" "}
                        {ethers.utils.formatEther(highestBidAmount)} by{" "}
                        {highestBider}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </section>

      <Faq />
      <Footer />
    </>
  );
};

export default CollectionItemDetails;

import React, { useState } from "react";
import { category1, category2, category3 } from "../../../containers/assets";

const ExploreTimeLine = () => {
  const [timeLine, setTimeLine] = useState([
    {
      month: "Jul",
      date: "11",
      year: "2022",
      title: "#1 NFT Marketplace",
      description:
        "New Wealth Colab launches NFT Marketplace for buying and selling of exclusive NFT Membership to its users",
      img: category1,
    },
    {
      month: "Aug",
      date: "15",
      year: "2022",
      title: "#2 NFT Home Sales",
      description: "Instant purchase of NFT homes through marketplace",
      img: category2,
    },
    // {
    //   date: "28Apr2022",
    //   title: "#4 Ipsum dolor sit amet - consetetur sadipscing",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    //   img: category3,
    // },
  ]);
  return (
    <>
      <section className="sec collection-sec">
        <div className="container">
          <h2 className="sec-head">Explore Timeline</h2>
          <div className="timeline">
            {timeLine && timeLine.length > 0
              ? timeLine.map((x, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index % 2 === 0 ? "row flex-row-reverse" : "row"
                      }
                    >
                      <div className="timeline-col-right">
                        <div className="timeline-date">
                          <div className="timeline-time-icon">
                            <img src="images/date-calender.png" alt="" />
                          </div>
                          <div className="timeline-time-text">
                            <p>
                              <span>{x && x.month ? x.month : ""}</span>
                              <span className="c-date">
                                {x && x.date ? x.date : ""}
                              </span>
                              <span>{x && x.year ? x.year : ""}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-col-left">
                        <div className="timeline-col-in">
                          <div className="timeline-col-img">
                            <img src={x && x.img ? x.img : ""} alt="" />
                          </div>
                          <div className="timeline-col-con">
                            <h5>{x && x.title ? x.title : ""}</h5>
                            <p>{x && x.description ? x.description : ""}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>

          <div className="text-center">
            <a href="/" className="btn custom-btn v-all">
              Show More
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreTimeLine;

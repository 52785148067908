import { useEffect, useState, useRef } from "react";
import Faq from "../Faq/Faq";
import { ethers } from "ethers";

import { useLocation, useParams } from "react-router-dom";

// import ActivityList from "../RecentActivity/ActivityList";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Accordion from "react-bootstrap/Accordion";
import {
  category1,
  profile,
  user2,
  user5,
  etherscanLogo,
} from "../../containers/assets";
import { useDispatch, useSelector } from "react-redux";
import { getItemsById, getMembershipItemById } from "../../slice/itemSlice";
import { mintNFT, getPrice } from "../../blockchain/mintingContract";
import TransactionModal from "../Common/TransactionModal";
import { getProfileWalletAddress } from "../../slice/userSlice";
import axios from "axios";
import { apiConstants } from "../../constants/api.constants";

const ExploreCollectionItemDetails = (props) => {
  const url =
    process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BASE_API;

  const { state } = useLocation();
  const dispatch = useDispatch();

  const urlData = useParams();

  const [getauctionData, setAuctionData] = useState("");
  const [txStatus, setTxStatus] = useState("initial");
  const [profileWalletAddress, setProfileWalletAddress] = useState("");

  const [show, setShow] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [ownerNFT, setOwnerNFT] = useState("");
  const [highestBider, setHighestBider] = useState(0);
  const [highestBidAmount, setHighestBidAmount] = useState(0);
  const [item, setItem] = useState("");
  const [mintPrice, setMintPrice] = useState(null);

  const { itemData } = useSelector((state) => {
    return { itemData: state.item };
  });

  const [status, setStatus] = useState(0);

  const aRef = useRef();

  useEffect(() => {
    let obj = {
      itemId: urlData.id,
    };
    dispatch(getItemsById(obj));
  }, []);

  useEffect(async () => {
    if (itemData && itemData.value && itemData.value.item) {
      let result = await axios.post(
        `${url + apiConstants.GET_PROFILE_WALLET_ADDRESS}`,
        { wallet_address: itemData.value.item.owner }
      );
      setProfileWalletAddress(result.data.user);
      setItem(itemData.value.item);
      setMintPrice(await getPrice());
    } else if (itemData && itemData.value && itemData.value.item == null) {
      setStatus(1);
    }
  }, [itemData, status == 0]);

  useEffect(() => {
    let obj = {
      itemId: urlData.id,
    };
    dispatch(getMembershipItemById(obj));
  }, [status == 1]);

  console.log(item);
  useEffect(() => {
    if (item) {
      if (aRef == null) return;
      console.log(itemData.value.item);
      aRef.current.innerHTML = item.description;
    }
  }, [aRef, item.description]);

  if (!item) return <></>;

  const handleMint = (tokenId) => {
    console.log(tokenId);
    setTxStatus("loadFunction");
    mintNFT(tokenId)
      .then((data) => {
        setTxStatus("complete");
      })
      .catch((error) => {
        setTxStatus("initial");
      });
  };

  const handleEtherscan = () => {
    if (window.ethereum.networkVersion == 1) {
      let eth = `https://etherscan.io/address/${itemData.value.item.owner}`;
      window.open(eth);
    } else if (window.ethereum.networkVersion == 4) {
      let rin = `https://rinkeby.etherscan.io/address/${itemData.value.item.owner}`;
      window.open(rin);
    }
  };

  return (
    <>
      {txStatus === "initial" || txStatus === "complete" ? (
        <>
          <Header />
          {/* <section className="page-banner">
            <div className="container">
              <h2>Item Details</h2>
              <ul className="bredcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Item</li>
              </ul>
            </div>
          </section> */}

          <section className="sec explore-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="exp-detail-left">
                    <div className="trending-cat-col">
                      {/* <span className="like">
                    <i className="fas fa-heart"></i> 10k
                  </span> */}
                      <div className="trending-cat-img-detail">
                        <img src={item.image ? item.image : ""} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="exp-detail-right">
                    <div className="ext-name-tit">
                      <h4>
                        {item.name === "Builder Collection (NWCBM)"
                          ? "BUILDER Collection"
                          : item.name}
                      </h4>
                      <h3>
                        {item.name === "Builder Collection (NWCBM)"
                          ? "BUILDER"
                          : item.name}{" "}
                        #{item.tokenId}
                      </h3>
                      <hr />
                      {/* <div className="ext-name-tit-row"> */}
                      {/* <div class="det-top-left">
                          <span class="user-icon-l">
                            <img src="images/user-5.png" alt="" />{" "}
                            <span class="user-check">
                              <img src="images/user-check.png" alt="" />
                            </span>
                          </span>
                          <span class="user-id">@galaxi07</span>
                        </div> */}
                      {/* </div> */}
                      <div
                        class="ext-name-tit"
                        style={{
                          "border-bottom": "1px",
                          padding: "15px 0",
                        }}
                      >
                        <div className="exp-btn-g">
                          <button
                            className="btn custom-btn border-btn"
                            onClick={handleEtherscan}
                          >
                            <span className="btn-icon">
                              <img src={etherscanLogo} alt="" />
                            </span>
                            Etherscan
                          </button>
                        </div>
                      </div>

                      {/* <div class="exp-btn-g">
                           <a href="#" class="btn custom-btn border-btn"><span class="btn-icon"><img src="images/etherscan-logo-circle.png" alt="" /></span> Etherscan</a>
                        </div> */}
                      <div class="trending-cat-det">
                        <div class="det-top-right">
                          <a href="#" class="price">
                            {item.name == "Builder Collection (NWCBM)"
                              ? mintPrice + " ETH"
                              : "1.00 ETH"}
                            <span>Price</span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="ext-name-tit"
                        style={{
                          "border-bottom": "1px",
                          padding: "30px 0",
                        }}
                      >
                        <div class="exp-btn-g">
                          {item.name == "Builder Collection (NWCBM)" ? (
                            <button
                              className="btn custom-btn wallet-btn"
                              onClick={() => handleMint(item.tokenId)}
                              style={{
                                width: "165px",
                              }}
                            >
                              Mint
                            </button>
                          ) : (
                            <div>
                              <a href="#" class="btn custom-btn wallet-btn">
                                Buy Now
                              </a>
                              <a href="#" class="btn custom-btn wallet-btn">
                                Make Offer
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <hr />
                    </div>

                    {/* <div className="trending-cat-det">
                  <div className="det-top-right"></div>
                </div> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="exp-detail-left">
                    {/* <ul className="accordion-list1"> */}
                    <Accordion defaultActiveKey="1">
                      {/* <li className="active"> */}
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="m-2">
                          <b>Descriptions</b>
                        </Accordion.Header>
                        <Accordion.Body className="answer">
                          <div ref={aRef} />
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* </li> */}
                      {/* <Accordion.Item eventKey="2">
                        <Accordion.Header className="m-2">
                          <b>Details</b>
                        </Accordion.Header>
                        <Accordion.Body className="answer">
                          <p>{item.description}</p>
                        </Accordion.Body>
                      </Accordion.Item> */}
                    </Accordion>
                    {/* </ul> */}
                  </div>
                </div>

                {getauctionData && getauctionData.minPrice > 0 ? (
                  <div className="col-md-6">
                    <div className="exp-detail-right">
                      <span>Highest Bid</span>
                      <div className="det-top-left bid-h-row">
                        <p>
                          <span className="user-id">
                            Placed a bid{" "}
                            {ethers.utils.formatEther(highestBidAmount)} by{" "}
                            {highestBider}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </section>

          <Faq />
          <Footer />
        </>
      ) : txStatus === "loadFunction" ? (
        <TransactionModal />
      ) : (
        ""
      )}
    </>
  );
};

export default ExploreCollectionItemDetails;

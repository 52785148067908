import { configureStore } from "@reduxjs/toolkit";
import * as slice from "../slice";

export const store = configureStore({
  reducer: {
    user: slice.userSlice,
    alerts: slice.alertSlice,
    auth: slice.authSlice,
    item: slice.itemSlice,
    collection: slice.collectionSlice,
    nft: slice.nftSlice,
    minting: slice.mintingSlice
  },
});

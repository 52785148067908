import React from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import HomeBanner from "./Banner/Banner";
import TrendingCategories from "./TrendingCategories/TrendingCategories";
import TopCollections from "./TopCollections/TopCollections";
import RecentActivity from "./RecentActivity/RecentActivity";
import ExploreTimeLine from "./ExploreTimeLine/ExploreTimeLine";
import Faq from "../Faq/Faq";

const Home = () => {
  const handleCreateCollection = () => {};

  return (
    <div>
      <Header />

      <HomeBanner />

      {/* <section className="sec create-sell">
        <div className="container">
          <h2 className="sec-head">Create & Sell New Wealth NFTs</h2>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="create-sell-col">
                <div className="create-sell-icon"><img src={nft1} alt="" /></div>
                <h4>Set up your wallet</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
              </div>
            </div>

            <Link to="/Create-Collection" className="col-sm-6 col-md-6 col-lg-3">
              <div className="create-sell-col">
                <div className="create-sell-icon"><img src={nft2} alt="" /></div>
                <h4>Create your collection</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
              </div>
            </Link>

            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="create-sell-col">
                <div className="create-sell-icon"><img src={nft3} alt="" /></div>
                <h4>Add your NFTs</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="create-sell-col">
                <div className="create-sell-icon"><img src={nft4} alt="" /></div>
                <h4>List them for sale</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <TrendingCategories /> */}

      {/* <TopCollections /> */}

      <RecentActivity />

      <ExploreTimeLine />

      <Faq />

      <Footer />
    </div>
  );
};

export default Home;

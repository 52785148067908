import React, { useState } from "react";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import { Modal, Button, InputGroup, Input, FormControl } from "react-bootstrap";
import { withdrawAuction } from "../../blockchain/marketplaceContract";

const UpdateBuyNowModal = (props) => {
  const { updateBuyNowShow, setUpdateBuyNowShow, tokenId, nftContractAddress } =
    props;
  const [buyNowPrice, setBuyNowPrice] = useState("");
  const [error, setError] = useState(false);

  const submitHandler = async () => {
    setUpdateBuyNowShow(false);
    const withdrawData = await withdrawAuction(nftContractAddress, tokenId);
    console.log("out", withdrawData);
    //TODO write function here for submit New buy now price
  };

  const handleSetBidAmount = (e) => {};

  const handleClose = () => {
    setUpdateBuyNowShow(false);
  };
  return (
    <>
      <Modal show={updateBuyNowShow}>
        <Modal.Header>
          <Modal.Title>Update Buy Now</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="New Buy Now Price"
                aria-describedby="basic-addon2"
                onChange={(e)=>setBuyNowPrice(e.target.value)}
                value={buyNowPrice}
              />
              <InputGroup.Text id="basic-addon2">ETH</InputGroup.Text>
            </InputGroup>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => submitHandler()}>
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UpdateBuyNowModal;

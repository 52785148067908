import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConstants } from "../constants/api.constants";
import { setAlert } from "./alertSlice";
import { authentication } from "./authSlice";
import { commonService } from "../service/common.service";

const url = process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BASE_API;

export const createItem = createAsyncThunk(
  apiConstants.CREATE_ITEM,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withTokenAndFormData(apiConstants.CREATE_ITEM, data)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setAlert({
              msg: "Item Created successfully",
              alertType: "success",
              timeout: 6000,
            })
          );
        }
        return response.data.success;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const getAllItems = createAsyncThunk(
  apiConstants.GET_ALL_ITEMS,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withToken(`${apiConstants.GET_ALL_ITEMS}?search=${data.search}`, data)
      .then((response) => {
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const getItemsByCollectionId = createAsyncThunk(
  apiConstants.GET_ITEMS_BY_COLLECTION_ID,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withOutToken(`${apiConstants.GET_ITEMS_BY_COLLECTION_ID}?search=${data.search}`, data)
      .then((response) => {
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const getItemsById = createAsyncThunk(
  apiConstants.GET_ITEM_BY_ID,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withOutToken(apiConstants.GET_ITEM_BY_ID, data)
      .then((response) => {
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

export const getAllItemsOwner = createAsyncThunk(
  apiConstants.GET_ALL_ITEMS_OWNER,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withToken(`${apiConstants.GET_ALL_ITEMS_OWNER}?search=${data.search}`, data)
      .then((response) => {
        if (response.data.success) {
          // dispatch(
          //   setAlert({
          //     msg: "Get Items successfully",
          //     alertType: "success",
          //     timeout: 6000,
          //   })
          // );
        }
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);


export const getMembershipItemById = createAsyncThunk(
  apiConstants.GET_ITEM_BY_ID_MEMBERSHIP_NFT,
  async (data, { dispatch, rejectWithValue }) => {
    return commonService
      .withOutToken(apiConstants.GET_ITEM_BY_ID_MEMBERSHIP_NFT, data)
      .then((response) => {
        return response.data;
      })
      .catch(async (err) => {
        let result = await errorHandler(err.response);
        dispatch(
          setAlert({
            msg: JSON.stringify(result),
            alertType: "error",
            timeout: 6000,
          })
        );
        rejectWithValue(err);
      });
  }
);

// export const getItemId = createAsyncThunk(
//   apiConstants.GET_ITEM_ID,
//   async (data, { dispatch, rejectWithValue }) => {

//     return commonService.withToken(apiConstants.GET_ITEM_ID, data).then(response => {
//       if (response.data.success) {
//         dispatch(setAlert({ msg: 'Get Items successfully', alertType: 'success', timeout: 6000 }))
//       }
//       return response.data;

//     }).catch(async (err) => {

//       let result = await errorHandler(err.response)
//       dispatch(setAlert({ msg: JSON.stringify(result), alertType: 'error', timeout: 6000 }))
//       rejectWithValue(err)

//     })

//   }
// );

const errorHandler = async (response) => {
  try {
    if (response) {
      let arr = response.data.errors.map((x, index) => {
        if (index !== response.data.errors.length) {
          return x.msg + ",";
        } else {
          return x.msg;
        }
      });
      return arr;
    }
  } catch (err) {
    console.log("======== Error in ErrorHandler user slice ========", err);
  }
};

const initialState = {
  value: "",
  status: "",
};

export const itemSlice = createSlice({
  name: "item",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getAllItems.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
    [getAllItemsOwner.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
    [getItemsByCollectionId.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
    [getItemsById.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
    [createItem.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "created";
    },
    [getMembershipItemById.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
  },
});

export default itemSlice.reducer;

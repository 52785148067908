import React from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createKyc } from "../../slice/kycSlice";

const UploadKyc = () => {
  // const FILE_SIZE = "300MB";
  // const SUPPORTED_FORMATS = ['pdf', 'xlsx', 'png', 'jpeg']
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      city: "",
      country: "",
      idCard: "",
      userId: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please Enter Name"),
      address: Yup.string().required("Please Enter address"),
      city: Yup.string().required("Please Enter city"),
      country: Yup.string().required("Please Enter country"),
      idCard: Yup.mixed().required("A file is required"),
      passport: Yup.mixed().required("A file is required"),
      // .test(
      //     "fileSize",
      //     "File too large",
      //     value => value && value.size <= FILE_SIZE
      // )
      // .test(
      //     "fileFormat",
      //     "Unsupported Format",
      //     value => value && SUPPORTED_FORMATS.includes(value.type)
      // ),
      userId: Yup.string().required("Please Enter userId"),
    }),

    onSubmit: (values) => {
      let obj = {
        name: values.name,
        address: values.address,
        city: values.city,
        country: values.country,
        idCard: values.idCard,
        passport: values.passport,
        userId: values.userId,
      };
      dispatch(createKyc(obj));
    },
  });

  const fileHandler = (e, name) => {
    formik.values[name] = e.target.files[0];
  };

  return (
    <>
      <Header />
      <section className="page-banner">
        <div className="container">
          <h2>KYC</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Upload Kyc</li>
          </ul>
        </div>
      </section>

      <section className="sec explore-sec">
        <div className="container">
          <div className="profile-row d-center">
            {/* <div className="profile-col-left">

                            <div className="profile-detail-l"> */}
            {/* <div className="login-form"> */}
            <form onSubmit={formik.handleSubmit} style={{ width: "60%" }}>
              <div className="login-form-feild">
                <input
                  type="text"
                  className="form-control"
                  placeholder="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <label className="text-danger">
                  {formik.errors.name && formik.touched.name
                    ? formik.errors.name
                    : ""}
                </label>
              </div>

              <div className="login-form-feild">
                <input
                  type="text"
                  className="form-control"
                  placeholder="address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <label className="text-danger">
                  {formik.errors.address && formik.touched.address
                    ? formik.errors.address
                    : ""}
                </label>
              </div>

              <div className="login-form-feild">
                <input
                  type="text"
                  className="form-control"
                  placeholder="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <label className="text-danger">
                  {formik.errors.city && formik.touched.city
                    ? formik.errors.city
                    : ""}
                </label>
              </div>

              <div className="login-form-feild">
                <input
                  type="text"
                  className="form-control"
                  placeholder="country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <label className="text-danger">
                  {formik.errors.country && formik.touched.country
                    ? formik.errors.country
                    : ""}
                </label>
              </div>

              <div className="login-form-feild">
                <input
                  className="form-control p-2"
                  type="file"
                  id="file-upload"
                  placeholder="passport"
                  name="passport"
                  onChange={(e) => fileHandler(e, "passport")}
                  onBlur={formik.handleBlur}
                />

                <label className="text-danger">
                  {formik.errors.passport && formik.touched.passport
                    ? formik.errors.passport
                    : ""}
                </label>
              </div>

              <div className="login-form-feild">
                <input
                  className="form-control p-2"
                  type="file"
                  id="file-upload"
                  placeholder="idCard"
                  name="idCard"
                  onChange={(e) => fileHandler(e, "idCard")}
                  onBlur={formik.handleBlur}
                />
                <label className="text-danger">
                  {formik.errors.idCard && formik.touched.idCard
                    ? formik.errors.idCard
                    : ""}
                </label>
              </div>

              <div className="login-form-feild">
                <input
                  type="text"
                  className="form-control"
                  placeholder="userId"
                  name="userId"
                  value={formik.values.userId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <label className="text-danger">
                  {formik.errors.userId && formik.touched.userId
                    ? formik.errors.userId
                    : ""}
                </label>
              </div>

              <div className="login-form-feild">
                <button type="submit" className="btn custom-btn wallet-btn">
                  Upload KYC
                </button>
              </div>
            </form>
          </div>
          {/* </div> */}
          {/* </div>

                    </div> */}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default UploadKyc;

import React, { useEffect, useState, useRef } from "react";
import Faq from "../Faq/Faq";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

// import ActivityList from "../RecentActivity/ActivityList";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import BidHistory from "../BidHistory/BidHistory";
import Accordion from "react-bootstrap/Accordion";
import {
  category1,
  recent2,
  recent3,
  user2,
  user5,
  etherscanLogo,
} from "../../containers/assets";
import { getItemsByCollectionId } from "../../slice/itemSlice";
import { mintNFT, getPrice } from "../../blockchain/mintingContract";
import TransactionModal from "../Common/TransactionModal";
import { getAllUnmintedNft } from "../../slice/mintingSlice";
import { getCollectionById } from "../../slice/collectionSlice";
import { apiConstants } from "../../constants/api.constants";
import axios from "axios";

const ExploreCollectionDetail = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlData = useParams();
  const { mintingData } = useSelector((state) => {
    return { mintingData: state.minting };
  });
  const { collectionData } = useSelector((state) => {
    return { collectionData: state.collection };
  });
  const [status, setStatus] = useState("initial");
  const [items, setItems] = useState([]);
  const [currentno, setCurrentPageNo] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [mintPrice, setMintPrice] = useState(null);
  const [profileWalletAddress, setProfileWalletAddress] = useState("");
  const url =
    process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BASE_API;

  const aRef = useRef();

  useEffect(() => {
    if (aRef == null) return;
    aRef.current.innerHTML = collectionData.value.description;
  }, [aRef, collectionData.value.description]);

  useEffect(() => {
    if (mintingData && mintingData.value) {
      setItems(mintingData.value.membershipNfts);
    }
  }, [mintingData]);

  useEffect(() => {
    let obj = {
      id: urlData.id,
    };
    dispatch(getCollectionById(obj));
  }, []);

  useEffect(async () => {
    if (collectionData && collectionData.value) {
      let result = await axios.post(
        `${url + apiConstants.GET_PROFILE_WALLET_ADDRESS}`,
        { wallet_address: collectionData.value.creator }
      );
      setProfileWalletAddress(result.data.user);
      if (collectionData.value.name == "Builder Collection (NWCBM)") {
        let obj = {
          offset: 0,
          search: "",
        };
        const data = await dispatch(getAllUnmintedNft(obj));
        setPageNo(data.payload.pages);
        setItems(data.payload.membershipNfts);
        setMintPrice(await getPrice());
      } else {
        let obj = {
          offset: 0,
          collectionId: urlData.id,
          search: "",
        };
        const data = await dispatch(getItemsByCollectionId(obj));
        console.log("data==>", data.payload);

        setPageNo(data.payload.pages);
        setItems(data.payload.items);
        setMintPrice(await getPrice());
      }
    }
  }, [collectionData]);

  const handleSearch = async (e) => {
    if (collectionData?.value.name == "Builder Collection (NWCBM)") {
      let obj = {
        offset: 0,
        search: e.target.value,
        // collectionId: urlData.id,
      };
      const data = await dispatch(getAllUnmintedNft(obj));
      setPageNo(data.payload.pages);
      setItems(data.payload.membershipNfts);
      setMintPrice(await getPrice());
    } else {
      let obj = {
        offset: 0,
        search: e.target.value,
        collectionId: urlData.id,
      };
      const data = await dispatch(getItemsByCollectionId(obj));
      console.log("data==>", data.payload);
      setPageNo(data.payload.pages);

      setItems(data.payload.items);
      setMintPrice(await getPrice());
    }
  };

  useEffect(() => {
    if (items.length) {
    }
  }, [items]);

  const [details, setDetails] = useState({
    username: "@galaxi07",
    view: "3",
    likes: "3000",
    price: "1.000 ETH",
    img: category1,
    userProfile: user2,
  });

  const addDefaultSrc = (ev) => {
    ev.target.src = category1;
  };

  const handleMint = (tokenId) => {
    setStatus("loadFunction");
    mintNFT(tokenId)
      .then((data) => {
        setStatus("complete");
      })
      .catch((error) => {
        setStatus("initial");
      });
  };

  const handlePageClick = (event) => {
    setCurrentPageNo(event.selected - 1);
    let obj = {
      offset: event.selected,
      search: "",
    };
    dispatch(getAllUnmintedNft(obj));
  };

  const handleEtherscan = () => {
    console.log(collectionData);

    if (window.ethereum.networkVersion == 1) {
      let eth = `https://etherscan.io/address/${collectionData?.value.deployed_address}`;
      window.open(eth);
    } else if (window.ethereum.networkVersion == 4) {
      let rin = `https://rinkeby.etherscan.io/address/${collectionData?.value.deployed_address}`;
      window.open(rin);
    }
  };

  if (!collectionData && !collectionData.value) return <></>;
  return (
    <>
      {status === "initial" || status === "complete" ? (
        <>
          <Header />
          {/* <section className="page-banner">
            <div className="container">
              <h2>Explore Collection Details</h2>
              <ul className="bredcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Collection</li>
              </ul>
            </div>
          </section> */}

          <section className="sec explore-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="exp-detail-left">
                    <div className="trending-cat-col">
                      <div className="trending-cat-img-detail">
                        <img
                          src={
                            collectionData.value.logo_image
                              ? collectionData.value.logo_image
                              : ""
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="exp-detail-right">
                    <div className="ext-name-tit">
                      {/* <h4>
                        {collectionData.value.name ===
                        "Builder Collection (NWCBM)"
                          ? "BUILDER Collection"
                          : collectionData.value.name}{" "}
                      </h4> */}
                      <h3>
                        {collectionData.value.name ===
                        "Builder Collection (NWCBM)"
                          ? "BUILDER Collection"
                          : collectionData.value.name}{" "}
                      </h3>
                      <div class="ext-name-tit-row">
                        <div class="det-top-left">
                          <span class="user-icon-l">
                            <img
                              src={
                                profileWalletAddress.profile_image
                                  ? profileWalletAddress.profile_image
                                  : user5
                              }
                              alt=""
                            />
                            <span class="user-check">
                              <img src="images/user-check.png" alt="" />
                            </span>
                          </span>
                          <a
                            href={
                              "/profileCollection/" +
                              profileWalletAddress.wallet_address
                            }
                            class="user-id"
                          >
                            @
                            {profileWalletAddress.name != ""
                              ? profileWalletAddress?.name?.replace(/\s+/g, "")
                              : profileWalletAddress.wallet_address}
                          </a>
                        </div>
                      </div>
                      <hr />
                      <div class="ext-name-tit">
                        <div className="exp-btn-g">
                          <button
                            className="btn custom-btn border-btn"
                            onClick={handleEtherscan}
                          >
                            <span className="btn-icon">
                              <img src={etherscanLogo} alt="" />
                            </span>{" "}
                            Etherscan
                          </button>
                        </div>
                      </div>
                      {/* <div class="ext-name-tit">
                        <div class="exp-btn-g">
                           <a href="#" class="btn custom-btn border-btn"><span class="btn-icon"><img src="images/etherscan-logo-circle.png" alt="" /></span> Etherscan</a>
                        </div>
                        <div class="trending-cat-det">
                           <div class="det-top-right">
                              <a href="#" class="price">1.000 ETH <small>( +22.5 ETH)</small> <span>Price</span></a>
                           </div>
                        </div>
                        <div class="exp-btn-g">
                           <a href="#" class="btn custom-btn wallet-btn">Buy Now</a>
                           <a href="#" class="btn custom-btn wallet-btn">Make Offer</a>
                        </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="exp-detail-left">
                    {/* <ul className="accordion-list1"> */}
                    <Accordion defaultActiveKey="1">
                      {/* <li className="active"> */}
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="m-2">
                          <b>Descriptions</b>
                        </Accordion.Header>
                        <Accordion.Body className="answer">
                          <div ref={aRef} />
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* </li> */}
                      {/* <Accordion.Item eventKey="2">
                        <Accordion.Header className="m-2">
                          <b>Details</b>
                        </Accordion.Header>
                        <Accordion.Body className="answer">
                          <p>{collectionData.value.description}.</p>
                        </Accordion.Body>
                      </Accordion.Item> */}
                    </Accordion>
                    {/* </ul> */}
                  </div>
                </div>
                {/* <div className="col-md-6">
                <BidHistory />
              </div> */}
              </div>

              {/* <ActivityList /> */}
              <section className="sec explore-sec">
                <div className="container">
                  {" "}
                  {/* <div className="row">
                    <div className="col-md-6 text-secondary">
                      {itemData?.value.items.length} items
                    </div>
                  </div> */}
                </div>
                <div className="container">
                  <div className="d-right">
                    <div className="filter-form">
                      <div className="filter-form-feild"></div>
                      <div className="filter-form-feild">
                        <input
                          type="text"
                          name="search"
                          className="form-control"
                          placeholder="Search"
                          onChange={handleSearch}
                        />
                        <button type="button">
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="item-row">
                    <div className="row">
                      {items && items.length > 0 ? (
                        items.map((x, index) => {
                          return (
                            <div
                              className="col-sm-6 col-md-6 col-lg-6 col-xl-4"
                              key={index}
                            >
                              <div className="trending-cat-col">
                                <div className="trending-cat-img">
                                  <img
                                    onError={addDefaultSrc}
                                    src={x.image ? x.image : x.media_file}
                                    alt=""
                                    onClick={() =>
                                      navigate(
                                        `/explore-collection-item-detail/${x._id}`
                                      )
                                    }
                                  />
                                </div>
                                <div className="trending-cat-det">
                                  <div class="det-top">
                                    <div class="det-top-left">
                                      {/* <span class="user-icon-l">
                                        <img src="images/user2.jpg" alt="" />{" "}
                                        <span class="user-check">
                                          <img
                                            src="images/user-check.png"
                                            alt=""
                                          />
                                        </span>
                                      </span>
                                      <span class="user-id">@Jamy09</span> */}
                                    </div>

                                    <div class="det-top-right">
                                      <a href="#" class="price">
                                        {x.name == "Builder Collection (NWCBM)"
                                          ? mintPrice + " ETH"
                                          : "1.00 ETH"}
                                        <span>Price</span>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="det-top">
                                    <div
                                      className="det-top-left"
                                      onClick={() =>
                                        navigate(
                                          `/explore-collection-item-detail/${x._id}`
                                        )
                                      }
                                    >
                                      <span className="pro-name">
                                        {x && x.name
                                          ? x.name ===
                                            "Builder Collection (NWCBM)"
                                            ? "BUILDER "
                                            : x.name.charAt(0).toUpperCase() +
                                              x.name.slice(1)
                                          : ""}{" "}
                                        #{x && x.tokenId ? x.tokenId : ""}
                                      </span>
                                    </div>

                                    {x.name == "Builder Collection (NWCBM)" ? (
                                      <></>
                                    ) : (
                                      <div className="det-top-right">
                                        {x.minted ? (
                                          <span style={{ color: "green" }}>
                                            Minted
                                          </span>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            Minted
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {x.name == "Builder Collection (NWCBM)" ? (
                                    <div className="text-center">
                                      <button
                                        className="btn custom-btn wallet-btn"
                                        onClick={() => handleMint(x.tokenId)}
                                      >
                                        Mint
                                      </button>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {/* <div className="text-center">
                                  <a
                                    className="btn custom-btn wallet-btn"
                                    onClick={() =>
                                      navigate("/explore-collection-item-detail", {
                                        state: {
                                          itemId: x._id,
                                          description: x.description,
                                          name: x.name,
                                          image: x.media_file
                                        },
                                      })
                                    }
                                  >
                                    Details
                                  </a>
                                </div> */}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-center">
                          Record Not found
                        </div>
                      )}
                    </div>
                    <div className="d-center">
                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageNo}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                </div>
              </section>

              {/* <div className="collectible-details">
                <h2 className="sec-head">Collectible Details</h2>
                <p className="sub-head">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam.
                </p>

                <div className="row align-items-center">
                  <div className="col-md-5">
                    <div className="collectin-img">
                      <img src={recent2 ? recent2 : ""} alt="" />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="collectin-con">
                      <h4>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </h4>

                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr.
                      </p>

                      <p>
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet.
                      </p>
                      <br />
                      <button className="btn custom-btn">
                    Read More
                  </button>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center flex-row-reverse">
                  <div className="col-md-5">
                    <div className="collectin-img">
                      <img src={recent3 ? recent3 : ""} alt="" />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="collectin-con">
                      <h4>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </h4>

                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr.
                      </p>

                      <p>
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet.
                      </p>
                      <br />
                      <button className="btn custom-btn">
                    Read More
                  </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </section>

          <Faq />
          <Footer />
        </>
      ) : status === "loadFunction" ? (
        <TransactionModal />
      ) : (
        ""
      )}
    </>
  );
};

export default ExploreCollectionDetail;

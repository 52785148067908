import React, { useEffect } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { login } from "../../slice/userSlice";
// import { setAlert } from '../../slice/alertSlice';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      wallet_address: "",
    },
    validationSchema: Yup.object().shape({
      wallet_address: Yup.string().required("Please Enter Wallet Address"),
    }),

    onSubmit: (values) => {
      let obj = {
        wallet_address: values.wallet_address,
      };
      dispatch(login(obj));
    },
  });

  const { user, auth } = useSelector((state) => {
    return { user: state.user, auth: state.auth };
  });

  useEffect(() => {
    if (auth && Object.keys(auth).length > 0) {
      if (auth.isLoggedin && user.value) {
        navigate("/profile");
      }
    }
  }, [user]);

  const rememberHandler = (e) => {
    if (e) {
    } else {
      // dispatch(setAlert({ msg: 'Wallet Address Required', alertType: 'error', timeout: 6000 }))
    }
  };

  return (
    <>
      <Header />
      <section className="page-banner">
        <div className="container">
          <h2>Log in</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Log in</li>
          </ul>
        </div>
      </section>

      <section className="sec explore-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="login-box login-img">
                <video width="100%" height="468px" controls>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  <source src="mov_bbb.ogg" type="video/ogg" />
                  Your browser does not support HTML video.
                </video>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login-box">
                <h2>Login your Account</h2>
                <p className="log-with">
                  <a href="/" className="social-1">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="/" className="social-2">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="/" className="social-4">
                    <i className="fab fa-google"></i>
                  </a>
                </p>
                <p>or use your email</p>

                <div className="login-form">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="login-form-feild">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Wallet Address here"
                        name="wallet_address"
                        value={formik.values.wallet_address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label className="text-danger">
                        {formik.errors.wallet_address &&
                        formik.touched.wallet_address
                          ? formik.errors.wallet_address
                          : ""}
                      </label>
                    </div>

                    <div className="login-form-feild rem-pass">
                      <div className="rem">
                        <input
                          className="styled-checkbox"
                          id="styled-checkbox-1"
                          type="checkbox"
                          value="value1"
                        />
                        <label
                          htmlFor="styled-checkbox-1"
                          onClick={() =>
                            rememberHandler(formik.values.wallet_address)
                          }
                        >
                          Remember Me
                        </label>
                      </div>
                      <div className="pass">
                        <a href="/">Lost password ?</a>
                      </div>
                    </div>

                    <div className="login-form-feild">
                      <button
                        type="submit"
                        className="btn custom-btn wallet-btn"
                      >
                        Log In Now
                      </button>
                    </div>
                  </form>
                </div>

                <p>
                  Don't you have an account?{" "}
                  <a href="/signup">Create Account</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Login;

export const apiConstants = {
  //user apis

  LOGIN: "/user/login",
  SIGNUP: "/user/signup",
  UPDATE_PROFILE: "/user/updateProfile",
  LOGOUT: "/user/logout",
  GET_PROFILE: "/user/getProfile",
  UPDATE_PROFILE_IMAGE: "/user/updateProfilePic",
  SUBSCRIBE: "/newsLetter/subscribe",
  GET_PROFILE_WALLET_ADDRESS: "/user/getProfileByWalletAddress",


  // KYC Api's
  CREATE_KYC: "/kyc/createKyc",

  // Collection Api's
  CREATE_COLLECTION: "/collection/createCollection",
  GET_ALL_COLLECTIONS: "/collection/getAllCollections",
  GET_ALL_COLLECTIONS_BY_CREATOR: "/collection/getCollectionDetailsByCreator",
  GET_ALL_COLLECTIONS_BY_NAME: "/collection/getCollectionDetailsByName",
  GET_COLLECTION_BY_ID: "/collection/getCollectionDetailsById",
  UPDATE_COLLECTION_DETALS_BYID: "/collection/updateCollectionDetailsById",

  // Items Api's

  CREATE_ITEM: "/item/createItem",
  GET_ALL_ITEMS: "/item/getAllItems",
  GET_ALL_ITEMS_OWNER: "/item/getItemDetailsByOwner",
  UPDATE_ITEM_DETAIL_BYID: "/item/updateItemDetailsByItemId",
  GET_ITEMS_BY_COLLECTION_ID: "/item/getItemDetailsByCollectionId",
  GET_ITEM_DETAIL_BYNAME: "/item/getItemDetailsByName",
  GET_ITEM_BY_ID: "/item/getItemDetailsByItemId",

  // NFT API'ss

  CREATE_NFT_ACTION: "/nftAuction/createNftAuction",
  GET_NFT_BY_COLLECTION_NAME: "/nftAuction/getNftByCollectionName",
  GET_NFT_DETAIL: "/nftAuction/getNftDetail",
  GET_ALL_NFT_UNSOLD: "/nftAuction/getAllUnsoldNft",
  GET_NFT_UPDATE_DETAIL: "/nftAuction/updateNftDetail",

  // Minting membership api

  GET_ALL_UNMINTED_NFT: "/membership/getAllUnMintedNFt",
  GET_ALL_UPDATE_MEMBERSHIP_NFT: "/membership/updateMembershipNft",
  GET_ITEM_BY_ID_MEMBERSHIP_NFT: "/membership/getMembershipNftDetailsByItemId"


  //another apis here
};

import React, { useEffect, useState } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import { category1 } from "../../containers/assets";

import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { getAllCollections } from "../../slice/collectionSlice";
import * as Yup from "yup";
import { apiConstants } from "../../constants/api.constants";
import { commonService } from "../../service/common.service";
import { deployContract } from "../../blockchain/collectionDeploy";

const ExploreCollectionList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentno, setCurrentPageNo] = useState(0);
  const [status, setStatus] = useState("initial");

  const [filteredCollection, setFilteredCollection] = useState([]);
  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);
  const { collectionData } = useSelector((state) => {
    return { collectionData: state.collection };
  });

  useEffect(() => {
    let obj = {
      offset: 0,
      search: search,
    };
    dispatch(getAllCollections(obj));
  }, []);

  useEffect(() => {
    if (collectionData && collectionData?.value.collections) {
      setList(collectionData.value.collections);
      setFilteredCollection(collectionData.value.collections);
    }
  }, [collectionData]);

  const handlePageClick = (pageno) => {
    setCurrentPageNo(pageno.selected - 1);
    let obj = {
      offset: pageno.selected,
      deployed: true,
    };
    dispatch(getAllCollections(obj));
  };

  const handleDeploy = async (_id) => {
    const response = await commonService.withToken(
      apiConstants.GET_COLLECTION_BY_ID,
      {
        id: _id,
      }
    );

    const collection = response.data.collection;

    if (typeof collection != null) {
      // await deployContract(collection);
      deployContract(collection)
        .then((data) => {
          setStatus("complete");
        })
        .catch((error) => {
          setStatus("initial");
        });
    } else {
      alert("Something went wrong, couldn't mint the item");
    }
  };

  const handleSearch = (e) => {
    let obj = {
      offset: 0,
      search: e.target.value,
    };
    dispatch(getAllCollections(obj));
  };

  if (!list && !list.length) return <></>;
  return (
    <>
      {/* {
     (status === 'initial' || status === 'complete') ?
     <> */}
      <Header />
      <section className="page-banner">
        <div className="container">
          <h2>Explore Collection</h2>
          <ul className="bredcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Explore List</li>
          </ul>
        </div>
      </section>
      <section className="sec explore-sec">
        <div className="container">
          <div className="d-right">
            <div className="filter-form">
              <div className="filter-form-feild"></div>
              <div className="filter-form-feild">
                <input
                  type="text"
                  name="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleSearch}
                />
                <button type="button">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="item-row">
            <div className="row">
              {filteredCollection && filteredCollection.length > 0 ? (
                filteredCollection.map((x, index) => {
                  return (
                    <>
                      {x.deployed ? (
                        <div
                          className="col-sm-6 col-md-6 col-lg-6 col-xl-4"
                          key={index}
                        >
                          <div className="trending-cat-col">
                            <div className="trending-cat-img">
                              <img
                                src={
                                  x && x.logo_image ? x.logo_image : category1
                                }
                                alt=""
                                onClick={() =>
                                  navigate(
                                    `/explore-collection-details/${x._id}`
                                  )
                                }
                              />
                            </div>
                            <div className="trending-cat-det">
                              <div className="det-top">
                                <div
                                  className="det-top-left"
                                  onClick={() =>
                                    navigate(
                                      `/explore-collection-details/${x._id}`
                                    )
                                  }
                                >
                                  <span className="pro-name">
                                    {x && x.name
                                      ? x.name === "Builder Collection (NWCBM)"
                                        ? "BUILDER Collection"
                                        : x.name.charAt(0).toUpperCase() +
                                          x.name.slice(1)
                                      : ""}
                                  </span>
                                </div>

                                {/* <div className="det-top-right">
                              {x.deployed ? (
                                <span style={{ color: "green" }}>Deployed</span>
                              ) : (
                                <div onClick={() => handleDeploy(x._id)}>
                                  {" "}
                                  <button class="btn" style={{ color: "red" }}>
                                    Deployed
                                  </button>{" "}
                                </div>
                              )}
                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center">
                  Record Not found
                </div>
              )}
            </div>
            <div className="d-center">
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={collectionData.value.pages}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {/* </> : status === 'loadFunction' ? <TransactionModal /> : ""
      } */}
    </>
  );
};

export default ExploreCollectionList;

import React, { useEffect, useState } from "react";
import {
  category1,
  recent1,
  recent2,
  recent3,
  user2,
  user3,
} from "../../../containers/assets";
import OwlCarousel, { Options } from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getAllUnsoldNft } from "../../../slice/nftSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuctionData,
  makeBid,
} from "../../../blockchain/marketplaceContract";
import { getUri } from "../../../blockchain/itemMint";
import { axios } from "axios";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";

const RecentActivity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = {
    loop: false,
    margin: 10,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const { itemData } = useSelector((state) => {
    return { itemData: state.item };
  });
  const { nftData } = useSelector((state) => {
    return {
      nftData: state.nft,
    };
  });

  const [list, setList] = useState([]);
  const [filteredItem, setFilteredItem] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    let obj = {
      offset: 0,
    };
    // dispatch(getAllItems(obj));
    dispatch(getAllUnsoldNft());
  }, []);

  useEffect(() => {
    if (nftData && nftData?.value && nftData?.value.collection_unsoldArray) {
      setList(nftData.value.collection_unsoldArray);
    }
  }, [nftData]);

  useEffect(async () => {
    if (list) {
      let myList = [...list];
      let data1 = [];
      let i = list.length;

      list.map(async (item) => {
        let tokenId = item.tokenId;
        let nftContractAddress = item.nftContract;
        let isAuction = item.isAuction;
        i = i - 1;

        const get_uri = await getUri(nftContractAddress, tokenId);
        let imgJson;
        if (typeof get_uri != "string") {
          imgJson = axios.get(`${get_uri}`).then((res) => {
            console.log(res);
          });
        } else {
          imgJson = get_uri;
        }

        // const imgJson = await axios.get(`${get_uri}`)
        const d = await getAuctionData(nftContractAddress, tokenId);

        let obj = {
          ...item,
          minPrice: ethers.utils.formatEther(d.minPrice),
          buyNow: ethers.utils.formatEther(d.buyNowPrice),
          isAuction: isAuction,
          image:
            imgJson && imgJson.image != undefined ? imgJson.image : imgJson,
        };

        data1.push(obj);
        if (i == 0) {
          setStatus(true);
          setFilteredItem(JSON.parse(JSON.stringify(data1)));
        }
      });
    }
  }, [list.length]);

  const handleBuyNow = async (nftContractAddress, tokenId1, bidAmount) => {
    let tokenId = 8;
    let contract = "0xc5d3270c54810812EBE9288678CD2372F20356d4";
    let buyNowPrice = "0.1";
    let minPrice = "0.01";
    let auctionEnd = 1658068575;
    let bidIncreasePercentage = 100;
    const whitelistedBuyer = "0x0000000000000000000000000000000000000000";
    let feeRecipients = [];
    let feePercentages = [];
    const contractAddress = "0xc5d3270c54810812EBE9288678CD2372F20356d4";

    // const createSaleData = await createSale(
    //   contract,
    //   tokenId,
    //   buyNowPrice,
    //   whitelistedBuyer,
    //   feeRecipients,
    //   feePercentages
    // );

    // const createSaleData = await createNewNftAuction(
    //   contract,
    //   tokenId,
    //   minPrice,
    //   buyNowPrice,
    //   auctionEnd,
    //   bidIncreasePercentage,
    //   feeRecipients,
    //   feePercentages
    // );

    // const createSaleData = await createDefaultNftAuction(
    //   contract,
    //   tokenId,
    //   minPrice,
    //   buyNowPrice,
    //   feeRecipients,
    //   feePercentages
    // );

    const bid = await makeBid(
      nftContractAddress,
      tokenId,
      bidAmount.toString()
    );
  };

  const [slider, setSlider] = useState([
    {
      categoryImg: recent1,
      userProfile: user2,
      username: "@Jamy09",
      price: "1.000 ETH",
      name: "The Real eiroto",
      unit: "22.5 ETH 1/12",
    },
    {
      categoryImg: recent2,
      userProfile: user3,
      username: "@udrprip002",
      price: "1.000 ETH",
      name: "Monttie",
      unit: "22.5 ETH 1/12",
    },
    {
      categoryImg: recent3,
      userProfile: user2,
      username: "@galaxi07",
      price: "1.000 ETH",
      name: "The youe home",
      unit: "22.5 ETHuser2 1/12",
    },
    {
      categoryImg: recent1,
      userProfile: user3,
      username: "@Jamy09",
      price: "1.000 ETH",
      name: "The Real eiroto",
      unit: "22.5 ETH 1/12",
    },
    {
      categoryImg: recent2,
      userProfile: user2,
      username: "@Jamy09",
      price: "1.000 ETH",
      name: "The Real eiroto",
      unit: "22.5 ETH 1/12",
    },
    {
      categoryImg: recent3,
      userProfile: user3,
      username: "@Jamy09",
      price: "1.000 ETH",
      name: "The Real eiroto",
      unit: "22.5 ETH 1/12",
    },
  ]);

  const addDefaultSrc = (ev) => {
    ev.target.src = category1;
  };
  return (
    <section className="sec trending-cat">
      <div className="container">
        <h2 className="sec-head">TRENDING NFTS</h2>
        <p className="sub-head">Latest Buys, Sells and recent Bids</p>

        <div className="activity-slider crousel-icon">
          <OwlCarousel className="owl-theme" {...options}>
            {filteredItem && filteredItem.length > 0
              ? filteredItem.map((x, index) => {
                  return (
                    <div
                      // className="col-sm-6 col-md-6 col-lg-6 col-xl-4"
                      key={index}
                    >
                      <div className="trending-cat-col">
                        {/* <span className="like"><i className="fas fa-heart"></i> {x && x.like ? x.like : ''}</span> */}
                        <div className="trending-cat-img">
                          <img onError={addDefaultSrc} src={x.image} />
                        </div>
                        <div className="trending-cat-det">
                          <div className="det-top">
                            <a
                              // to={`/explore-details/${x._id}`}
                              className="det-top-left"
                              onClick={() =>
                                navigate("/explore-details", {
                                  state: {
                                    itemId: x._id,
                                    nftContractAddress: x.nftContract,
                                    tokenId: x.tokenId,
                                    collectionName: x.collection_name,
                                    isAuction: x.isAuction,
                                  },
                                })
                              }
                            >
                              <span className="pro-name">
                                {x && x.collection_name
                                  ? x.collection_name
                                  : ""}{" "}
                                #{x.tokenId}
                              </span>
                            </a>

                            <div className="det-top-right">
                              <span className="unit">
                                {" "}
                                {x && x.minPrice > 0
                                  ? "Min: " + x.minPrice + " ETH"
                                  : "Buy: " + x.buyNow + " ETH"}
                              </span>
                            </div>
                          </div>
                          <div className="text-center">
                            <button
                              className="btn custom-btn wallet-btn"
                              onClick={() =>
                                handleBuyNow(
                                  x.nftContract,
                                  x.tokenId,
                                  x.minPrice > 0 ? x.minPrice : x.buyNow
                                )
                              }
                            >
                              {x.minPrice > 0 ? "Bid" : "Buy Now"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default RecentActivity;

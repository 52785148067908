import React, { useState } from "react";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import { Modal, Button, InputGroup, Input, FormControl } from "react-bootstrap";
import { updateWhitelistedBuyer } from "../../blockchain/marketplaceContract";

const UpdateWhitelistedAddressModal = (props) => {
  const {
    setUpdateWhitelistedAddressShow,
    updateWhitelistedAddressShow,
    tokenId,
    nftContractAddress,
  } = props;
  const [whitelistedAddress, setWhiteListedAddress] = useState("");
  const [error, setError] = useState(false);

  const submitHandler = async () => {
    setUpdateWhitelistedAddressShow(false);
    const whitelistedAddress = await updateWhitelistedBuyer(
      nftContractAddress,
      tokenId,
      whitelistedAddress
    );
    console.log("out", whitelistedAddress);
    //TODO write function here for submit white listed address
  };

  const handleClose = () => {
    setUpdateWhitelistedAddressShow(false);
  };
  return (
    <>
      <Modal show={updateWhitelistedAddressShow}>
        <Modal.Header>
          <Modal.Title>Update White listed Address</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Enter New Whitelisted Address"
                aria-describedby="basic-addon2"
                onChange={(e) => setWhiteListedAddress(e.target.value)}
                value={whitelistedAddress}
              />
            </InputGroup>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => submitHandler()}>
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UpdateWhitelistedAddressModal;

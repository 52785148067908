import React, { useState } from "react";
import { Modal, Button, InputGroup, Input, FormControl } from "react-bootstrap";

const TransactionModal = (props) => {
  const { show, setShow } = props;
  const [bidAmount, setBidAmount] = useState(0.0);
  const [error, setError] = useState(false);

  return (
    <>
      <Modal show={true} backdrop="static">
        <Modal.Header>
          {/* <Modal.Title>Transcation Loader</Modal.Title> */}
        </Modal.Header>
        <form>
          <Modal.Body>
            <h4>Wating for request completed...</h4>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default TransactionModal;

import { ethers } from "ethers";
import { metaMaskClientCheck, connectWallet } from "./wallet";
import { useDispatch } from "react-redux";

import erc721 from "./NFT.json";
import erc721Default from "./NFT_NEW_WEALTH.json";
import { apiConstants } from "../constants/api.constants";

import { commonService } from "../service/common.service";
import { ownerOfNFT } from "./marketplaceContract";
import TransactionModal from "../components/Common/TransactionModal";

const NFTabi = erc721.abi;
const NFTabiDefault = erc721Default.abi;

let providerForView;

if (process.env.REACT_APP_ENV == "development") {
  providerForView = new ethers.providers.AlchemyProvider(
    "rinkeby",
    process.env.REACT_APP_ALCHEMY_RINKEBY_API
  );
} else if (process.env.REACT_APP_ENV == "production") {
  providerForView = new ethers.providers.AlchemyProvider(
    "homestead",
    process.env.REACT_APP_ALCHEMY_MAINNET_API
  );
}

async function getNftContract(nftContractAddress) {
  try {
    if (process.env.REACT_APP_NFT_CONTRACT_ADDRESS == nftContractAddress) {
      return new ethers.Contract(
        nftContractAddress,
        NFTabiDefault,
        providerForView
      );
    } else {
      return new ethers.Contract(nftContractAddress, NFTabi, providerForView);
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

// ===> View functions. Doesn't costs gas

export async function getUri(nftContractAddress, tokenId) {
  const nftContract = await getNftContract(nftContractAddress);

  console.log(nftContract, tokenId);
  return await nftContract.tokenURI(tokenId);
}

export async function getOwner(nftContractAddress, tokenId) {
  const nftContract = getNftContract(nftContractAddress);
  console.log(nftContractAddress, tokenId,nftContract);

  return await nftContract.ownerOf(parseInt(tokenId));
}

// ===> End View functions. Doesn't costs gas

// ===> State change functions. Costs gas

async function mint(nftContract, itemDetails) {
  const provider = metaMaskClientCheck();

  console.log("Function is Here =====> " + itemDetails.metadata);

  const tx = await nftContract
    .connect(provider.getSigner())
    .createNFtToken(itemDetails.metadata);

  console.log("Function is Here =====> ");

  // const receipt = await tx.wait();
  // Loader implement here
  let receipt;
  receipt = await tx.wait();

  console.log("recipt===>", receipt);
  const event = receipt.events.find((event) => event.event === "Transfer");

  const [from, to, tokenId] = event.args;

  console.log(from, to, tokenId);

  if (receipt.status == 1 && from == ethers.constants.AddressZero) {
    try {
      await commonService.withToken(apiConstants.UPDATE_ITEM_DETAIL_BYID, {
        itemId: itemDetails._id,
        minted: true,
        tokenId: parseInt(tokenId._hex, 16),
        contractAddress: await nftContract.address,
      });
    } catch (err) {
      console.log(err);
    }
  } else {
    alert("Unable to to mint the item as NFT. Please try again");
  }
}

export async function mintItem(itemDetails) {
  const provider = metaMaskClientCheck();

  try {
    let nftContract;

    if (itemDetails.collectionDetails != "") {
      const response = await commonService.withToken(
        apiConstants.GET_COLLECTION_BY_ID,
        {
          id: itemDetails.collectionDetails,
        }
      );

      console.log(response.data.collection.deployed_address);

      nftContract = await getNftContract(
        response.data.collection.deployed_address
      );

      const owner = await nftContract.owner();

      const currentAddress = await provider.getSigner().getAddress();

      console.log(currentAddress, owner);

      if (owner == currentAddress) {
        await mint(nftContract, itemDetails);
      } else {
        alert(
          "You are not the owner of the collection to mint this item under the given collection"
        );
      }
    } else {
      console.log("Function is Here =====> ");

      nftContract = await getNftContract(
        process.env.REACT_APP_DEFAULT_NFT_CONTRACT
      );
      await mint(nftContract, itemDetails);
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

// ===> End of State change functions. Costs gas

import React, { useEffect, useState } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import { category1 } from "../../containers/assets";

import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { getAllItemsOwner } from "../../slice/itemSlice";

import * as Yup from "yup";
import { mintItem } from "../../blockchain/itemMint";
import { apiConstants } from "../../constants/api.constants";
import { commonService } from "../../service/common.service";
import TransactionModal from "../Common/TransactionModal";

const ItemList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentno, setCurrentPageNo] = useState(0);
  const [list, setList] = useState([]);
  const [status, setStatus] = useState("initial");

  const [newList, setNewList] = useState([]);

  const { itemData } = useSelector((state) => {
    return { itemData: state.item };
  });
  useEffect(() => {
    let obj = {
      offset: 0,
      search: "",
    };
    dispatch(getAllItemsOwner(obj));
  }, []);

  useEffect(() => {
    if (itemData && itemData.value && itemData.value.items) {
      setList(itemData.value.items);
    }
  }, [itemData]);

  useEffect(() => {
    if (list) {
      let data1 = [];
      let i = list.length;
      list.map((data) => {
        i = i - 1;
        let obj = {
          ...data,
          imageLink: "",
        };
        data1.push(obj);

        if (i == 0) {
          setNewList(JSON.parse(JSON.stringify(data1)));
        }
      });
    }
  }, [list.length]);

  const handleChange = (e) => {
    let obj = {
      offset: 0,
      search: e.target.value,
    };
    dispatch(getAllItemsOwner(obj));
  };

  const handleMint = async (name) => {
    setStatus("loadFunction");

    const item = await commonService.withToken(
      apiConstants.GET_ITEM_DETAIL_BYNAME,
      {
        name: name,
      }
    );

    if (typeof item != undefined) {
      //  let d = await mintItem(item.data.items);
      mintItem(item.data.items)
        .then((data) => {
          setStatus("complete");
        })
        .catch((error) => {
          setStatus("initial");
        });
    } else {
      alert("Something went wrong, couldn't mint the item");
    }
  };

  const handlePageChange = (pageno) => {
    setCurrentPageNo(pageno.selected - 1);
    let obj = {
      offset: pageno.selected,
      search: "",
    };
    dispatch(getAllItemsOwner(obj));
  };
  const handleClick = () => {
    navigate("/create-item");
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = category1;
  };
  return (
    <>
      {status === "initial" || status === "complete" ? (
        <>
          <Header />
          <section className="page-banner">
            <div className="container">
              <h2>Item</h2>
              <ul className="bredcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Item List</li>
              </ul>
            </div>
          </section>
          <section className="sec explore-sec">
            <div className="container">
              <div className="d-right">
                <div className="filter-form-feild">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    placeholder="Search"
                    onChange={handleChange}
                  />
                  <button type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
                <button
                  className="btn custom-btn wallet-btn"
                  onClick={handleClick}
                >
                  Create Item
                </button>
              </div>
              <div className="item-row">
                <div className="row">
                  {itemData &&
                  itemData?.value.items &&
                  itemData?.value.items.length > 0 ? (
                    newList.map((x, index) => {
                      return (
                        <div
                          className="col-sm-6 col-md-6 col-lg-6 col-xl-4"
                          key={index}
                        >
                          <div className="trending-cat-col">
                            <div className="trending-cat-img">
                              <img
                                onError={addDefaultSrc}
                                src={x.image}
                                alt=""
                                onClick={() =>
                                  navigate(`/Item-detail/${x._id}`, {
                                    state: {
                                      item: x,
                                    },
                                  })
                                }
                              />
                            </div>
                            <div className="trending-cat-det">
                              <div className="det-top">
                                <div
                                  className="det-top-left"
                                  onClick={() =>
                                    navigate(`/Item-detail/${x._id}`, {
                                      state: {
                                        item: x,
                                      },
                                    })
                                  }
                                >
                                  <span className="pro-name">
                                    {x && x.name ? x.name : ""}
                                  </span>
                                </div>

                                <div className="det-top-right">
                                  {x.minted ? (
                                    <span style={{ color: "green" }}>
                                      Minted
                                    </span>
                                  ) : (
                                    <div>
                                      <button
                                        class="btn"
                                        style={{ color: "red" }}
                                        onClick={() => handleMint(x.name)}
                                      >
                                        Mint
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex justify-content-center">
                      Record Not found
                    </div>
                  )}
                </div>
                <div className="d-center">
                  <ReactPaginate
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={itemData.value.pages}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      ) : status === "loadFunction" ? (
        <TransactionModal />
      ) : (
        ""
      )}
    </>
  );
};

export default ItemList;

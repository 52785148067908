import mintContractAbi from "./mintingContract.json";
import { ethers } from "ethers";
import { metaMaskClientCheck } from "./wallet";
import { apiConstants } from "../constants/api.constants";
import { commonService } from "../service/common.service";

let providerForView;

if (process.env.REACT_APP_ENV == "development") {
  providerForView = new ethers.providers.AlchemyProvider(
    "rinkeby",
    process.env.REACT_APP_ALCHEMY_RINKEBY_API
  );
} else if (process.env.REACT_APP_ENV == "production") {
  providerForView = new ethers.providers.AlchemyProvider(
    "homestead",
    process.env.REACT_APP_ALCHEMY_MAINNET_API
  );
}

export async function mintNFT(tokenId) {
  const provider = metaMaskClientCheck();

  console.log(
    "Minting Contract ===> " + process.env.REACT_APP_MINTING_CONTRACT
  );

  console.log("TokenId ===> " + tokenId);

  const mintingContract = new ethers.Contract(
    process.env.REACT_APP_MINTING_CONTRACT,
    mintContractAbi,
    provider
  );

  const signer = provider.getSigner();
  const mintPrice = await mintingContract.mintPrice();

  const tx = await mintingContract
    .connect(signer)
    .publicMint(tokenId, { value: mintPrice });

  console.log(tx);

  const receipt = await tx.wait();

  if (receipt.status == 1) {
    try {
      await commonService.withToken(
        apiConstants.GET_ALL_UPDATE_MEMBERSHIP_NFT,
        {
          tokenId: tokenId,
          minted: true,
        }
      );
    } catch (err) {
      console.log(err);
    }
  } else {
    alert("Unable to to mint the item as NFT. Please try again");
  }
}

export async function getPrice() {
  const mintingContract = new ethers.Contract(
    process.env.REACT_APP_MINTING_CONTRACT,
    mintContractAbi,
    providerForView
  );

  return ethers.utils.formatEther(await mintingContract.mintPrice());
}
